import React, { useEffect, useRef, useState } from 'react'
import { IoMdMore } from "react-icons/io";
import { Grid } from '@material-ui/core';
import VtubeCard from './VtubeCard';
import ReelSlider from '../ReelSlider';
import Axios from 'axios';
import Cookies from 'js-cookie';

const VtubeContents = ({open,setOpen,load}) => {
     const [cookieData, setCookieData] = useState({});
      const[videoData,setVideoData] = useState()

    
      

      console.log(videoData,'video')

     const getVideoData = async() => {
          let data = { "user_id":cookieData?.id,
              "api_key":cookieData?.api_key,
              }
          const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_all_videos`,
              data,
              {
                headers:{
                  'Content-Type': 'text/plain',
                  'Accept':'application/json, text/plain, */*'
                }
               });
               if(response.data.code === true){
                 setVideoData(response?.data?.response?.videos)
               }
      }
      
      useEffect(()=>{
        getVideoData()
      },[cookieData,load])
     
  useEffect(() => {

     let data = Cookies.getJSON('name');
     setCookieData(data?.user_data) 
    
   }, []);

   const handleCountViews = async(id) => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "video_id":id
        
        }

        console.log(id,'videoid')
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_view`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           
           
           return true
         }
  }

  return (

    
     <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:"70px"}}>

  <div id='ReelSlider_div' style={!open ?{ width:'94vw'} :{width:'82vw'}}>
      <ReelSlider/>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
      <Grid container
      style={{padding:"10px"}}
       spacing={2}
       alignItems='stretch'
       direction='row'
       justify='left'>

          {videoData && videoData.map((item,index)=>(
        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3} onClick={()=>handleCountViews(item?.video_id)}>
             <VtubeCard data={item}/>
        </Grid>

          ))}

        
      </Grid>

    </div>

   
    </div>
  )
}

export default VtubeContents
