import { Button, TextField } from '@material-ui/core'
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';

const UpdateDeatail = ({setOpen,setLoadDetail,data}) => {
    const [cookieData, setCookieData] = useState({});
    const[nick,setNick] = useState(data?.nickname)
    const[quote,setQuote] = useState(data?.favorite_quotes)
    const [describe,setDescribe] = useState(data?.about_description)
    const handleAddDeatil = async() => {
        let data2 = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "nickname":nick,
            "favorite_quotes": quote,
            "about_description": describe,
            "detail_id":data?.id
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_detail`,
            data2,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setQuote('')
                setNick('')
                setDescribe('')
                setOpen(false)
                setLoadDetail(true)
             }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>

    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <h4>About Yourself</h4>
      <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
    </div>

    <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
      <TextField variant='outlined' value={nick} fullWidth onChange={(e)=>setNick(e.target.value)} placeholder='Nick Name'/>

      <TextField variant='outlined' value={quote} multiline onChange={(e)=>setQuote(e.target.value)} fullWidth placeholder='Fav Quotes'/>

     

      <TextField variant='outlined' value={describe} fullWidth onChange={(e)=>setDescribe(e.target.value)} placeholder='Describe yourself' multiline rows={3}/>

      <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
        <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
        <Button onClick={handleAddDeatil} variant='contained'>Save</Button>
      </div>
    </div>
  </div>
  )
}

export default UpdateDeatail
