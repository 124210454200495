import React, { useState } from 'react'
import VtubeNavbar from '../VtubeNavbar'
import VideoDetails from './VideoDetails'
import RecommendedVideos from './RecommendedVideos'
import { Drawer } from '@material-ui/core'
import VtubeSidebar from '../VtubeSidebar'
import VtubeAppBar from '../VtubeAppBar'

const ActiveVideo = () => {
  const[open,setOpen] = useState(false);
  const[videoId,setVideoId] = useState();
  const[allVideos,setAllVideos] = useState()

  console.log(allVideos,'videos')
  return (
    <div>
        <VtubeNavbar open={open} setOpen={setOpen}/> 
          <Drawer 
                   anchor='left'
                   open={open} 
                   onClose={()=>setOpen(false)}>
                    <div style={{marginTop:"40px"}}>
                      <VtubeSidebar open={open} setOpen={setOpen} />
                      </div>
                   </Drawer>

        <div id='ActiveVideo' style={{display:'flex',justifyContent:"center"}}>
          <div id='VideoDeatils' style={{width:"60%"}}>
            <VideoDetails videoId={videoId} allVideos={allVideos}/>
          </div>
          <div style={{}}>
            <RecommendedVideos setVideoId={setVideoId} setAllVideos={setAllVideos}/>
            <VtubeAppBar/>
          </div>
        </div>     
    </div>
  )
}

export default ActiveVideo
