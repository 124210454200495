import { Button, TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';

const UpdateInfo = ({setOpen,data,setLoadInfo}) => {
  const[mob,setMob] = useState(data?.mobile_number)
  const[email,setEmail] = useState(data?.email)
  const[addr,setAddr] = useState(data?.address)
 
  const [cookieData, setCookieData] = useState({});

  const handleUpdatInfo = async() => {
    let data2 = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
       "mobile_number": mob,
    "email": email,
    "address": addr,
    "contact_id":data?.id
  }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_contact_info`,
        data2,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
          
            setOpen(false)
            setLoadInfo(true)
         }
}

useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data) 
   
  }, []);
  return (
    <div className='addPopup'>

    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
      <h4>About Yourself</h4>
      <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
    </div>

    <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
      <TextField variant='outlined' fullWidth placeholder='Mobile No' value={mob} onChange={(e)=>setMob(e.target.value)}/>

      <TextField variant='outlined' fullWidth placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>

      <TextField variant='outlined' fullWidth placeholder='Address' multiline rows={3} value={addr} onChange={(e)=>setAddr(e.target.value)}/>

      <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
        <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
        <Button onClick={handleUpdatInfo} variant='contained'>Save</Button>
      </div>
    </div>
  </div>
  )
}

export default UpdateInfo
