import React, { useEffect, useState } from 'react'
import InnerHeader from '../directives/innerHeader'
import InnerSidebar from '../directives/innerSidebar'
import { IoSearch } from 'react-icons/io5'
import Cookies from 'js-cookie';
import Axios from 'axios';
import { GiVote } from "react-icons/gi";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from 'react-js-pagination';

const VoteDiscover = () => {
  const [cookieData, setCookieData] = useState({});
  const[votes,setVotes] = useState([]);
  const[searchText,setSearchText] = useState('')
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Calculate the items to display on the current page
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = votes?.slice(indexOfFirstItem, indexOfLastItem);

  const getVotingData = async() => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "page":activePage,
        "limit":"10"
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/voting_list`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
          setVotes(response?.data?.recdata)
         }
}

const searchVotingData = async() => {
  let data = { "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
      "page":"1",
    "limit":"10",
    "title":searchText
      }
  const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/search_voting_titles`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response.data.code === true){
        setVotes(response?.data?.recdata)
       }
}




useEffect(()=>{
  searchVotingData()
},[searchText])

useEffect(()=>{
 getVotingData();
},[cookieData,activePage])

  useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data)

  
  }, []);
  return (
    <div>
       <InnerHeader/>

       <div style={{display:'flex',width:'100%',justifyContent:'center'}}>

        <div style={{width:'90%',marginTop:'100px',display:'flex',gap:'20px'}}>

            <div className='sidebar101'>
           <InnerSidebar/>
            </div>
            <div className='allVotesContainer' style={{width:'60%',height:'auto',border:'1px solid rgba(0,0,0,0.3)',padding:'20px',borderRadius:'10px'}}>
                  <div style={{fontSize:'22px'}}>All Surveys</div>
                  <div className="search-container4" style={{width:'40%',marginTop:'10px'}}>
                    <IoSearch className="search-icon"/>
                   <input  type="text" className="search-field" value={searchText} placeholder="Search votes..." onChange={(e)=>setSearchText(e.target.value)}/>
              
                </div>

              
{votes && [...votes].reverse().map((item,index)=>(
                <div key={index} onClick={()=>history.push(`/votingDetail/${item?.id}`)} style={{marginTop:'20px',display:'flex',alignItems:'center',gap:'10px',cursor:'pointer'}}>
                  <GiVote style={{fontSize:'28px'}}/>
                    <div style={{fontSize:'18px',fontWeight:'500'}}>{item?.title}</div>
                </div>

))}

<div style={{display:'flex',justifyContent:'center'}}>
         <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={votes?.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
         </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default VoteDiscover
