import React, { useState } from 'react';
import Overview from './Overview';
import WorkEdu from './WorkEdu';
import PlacesLived from './PlacesLived';
import ContactInfo from './ContactInfo';
import Family from './Family';
import Details from './Details';


const About = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="container555">
      <div className="tabs555">

        <h4>About</h4>
        {[ 'Work & Education', 'Places lived', 'Contact & Info','Family & Relatioships','Details about you'].map((label, index) => (
          <div
            key={index}
            className={`tab555 ${activeTab === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="tab555-panel">
        {/* {activeTab === 0 && <div><Overview/></div>} */}
        {activeTab === 0 && <div><WorkEdu/></div>}
        {activeTab === 1 && <div><PlacesLived/></div>}
        {activeTab === 2 && <div><ContactInfo/></div>}
        {activeTab === 3 && <div><Family/></div>}
        {activeTab === 4 && <div><Details/></div>}
       
      </div>
    </div>
  );
};

export default About;
