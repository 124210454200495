import React from 'react'
import { SlSocialInstagram } from "react-icons/sl";
import { ImFacebook } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Footer = () => {
  return (
    <div className='homeFooter'>
      <div className='footerComponent'>
        <div className='footer001' style={{height:'40%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
           <div>
           <img src='https://nftstars.shop/images/Logo3_new.png' style={{height:'80px'}}/>
           {/* <div style={{display:'flex',alignItems:'center',gap:'30px'}}>
           <div style={{color:'white'}}>Home</div>
           <div style={{color:'white'}}>About</div>
           <div style={{color:'white'}}>Service</div>
           <div style={{color:'white'}}>Product</div>
           <div style={{color:'white'}}>Contact</div>
         </div> */}
           </div>

           <div>
            {/* <div style={{color:'white'}}>Subscribe to stay tuned for latest updtae and feature</div>
            <div style={{color:'white',fontSize:'20px',fontWeight:'600'}}>Consulting</div> */}
            {/* <div style={{display:'flex'}}>
             <input style={{padding:'10px',background:'white'}} placeholder='Enter your mail'/>
             <button className='regButton2' style={{width:'37%',display:'flex',alignItems:'center',gap:'10px'}}>Subscribe </button>
            </div> */}
            {/* <div style={{display:'flex'}}>
            <div style={{color:'white'}}>Contact Us:</div>
            <div style={{color:'white',textDecoration:'underline'}}>demouser@gmail.com</div>
            </div> */}
           </div>

        </div>


        <div className='footer002' style={{width:'100%',borderTop:'1px solid white',display:'flex',justifyContent:'end',marginTop:'20px',paddingTop:'30px'}}>
        {/* <div style={{display:'flex',alignItems:'center',gap:'30px'}}>
           <div>Privacy policy</div>
           <div>Terms of use</div>
           <div >Sales and refunds</div>
           <div>Legal</div>
         </div> */}

         <div style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <a href='mailto:moorb7@hotmail.com'>
           <AiOutlineMail  style={{color:'white',fontSize:'40px'}}/>
          </a>
           {/* <SlSocialInstagram style={{color:'white',fontSize:'30px'}}/>
           <ImFacebook style={{color:'white',fontSize:'30px'}}/>
           <FaXTwitter style={{color:'white',fontSize:'30px'}}/> */}
         </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
