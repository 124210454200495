import React, { useState } from 'react'
import { RiSendPlane2Fill } from 'react-icons/ri'
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import Emoji from '../../../contents/Emoji'
import Axios from 'axios'
import { IoClose } from 'react-icons/io5'
import { Avatar } from '@material-ui/core'

const VideoDescriptionDra = ({setOpen}) => {
    const [replyStates, setReplyStates] = useState({});
    const [commentText,setCommentText] = useState(true)
    

    const toggleReply = (index) => {
      setReplyStates(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };

    const createComment =()=>{
        console.log(commentText)
    }
    const article =[
        {
            desc:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto adipisci odit nobis eos magni autem dolorum temporibus voluptatibus itaque, ad tenetur, molestias, porro laudantium in officiis nostrum. Cum, corporis iusto? Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur sunt placeat impedit deleniti, cum, adipisci provident vero, architecto quisquam quos fugiat dolores possimus saepe nulla? Voluptatibus quasi dolorum dolorem, laborum quas at itaque! Voluptas saepe quo mollitia quia natus reprehenderit?"
        }
    ]
  return (
    <div style={{height:'550px',width:'100%',position:'relative',display:'flex',flexDirection:'column'}}>
      <div style={{display:'flex',width:'98%',justifyContent:'space-between',marginTop:'10px',height:'50px',borderBottom:'1px solid black'}}>
      <div style={{height:'50px',padding:'0px 10px',borderRadius:'10px',left:'10px'}}>
      <div style={{color:'black',fontWeight:'500',fontSize:'24px'}}>Descrition</div>
                  {/* <div style={{fontSize:'18px',color:'black'}}>{reelTimeline && reelTimeline[currentStoryIndex]?.message}</div> */}
                                     
                </div>
          <IoClose onClick={()=>setOpen(false)} style={{fontSize:'25px',color:'black',cursor:'pointer'}}/>
      </div>

      
      <div id='views_time' style={{}}>

{article.map((item, index) => (
<div key={index} style={{color:"#131313",fontSize:"13px",padding:"10px 20px"}}>


        <div>
            <div style={{display:'flex',justifyContent:'space-evenly'}}>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center",margin:'5px 0'}}>
                    <p style={{fontSize:"20px",fontWeight:"500",color:"black",margin:"0"}}>28k</p>
                    <p style={{fontSize:"14px",fontWeight:"",color:"",margin:"0"}}>likes</p>
                </div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center",margin:'5px 0'}}>
                    <p style={{fontSize:"20px",fontWeight:"500",color:"black",margin:"0"}}>28634</p>
                    <p style={{fontSize:"14px",fontWeight:"",color:"",margin:"0"}}>Views</p>
                </div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center",margin:'5px 0'}}>
                    <p style={{fontSize:"20px",fontWeight:"500",color:"black",margin:"0"}}>14 Mar</p>
                    <p style={{fontSize:"14px",fontWeight:"",color:"",margin:"0"}}>2024</p>
                </div>
            </div>
            {item.desc}
            <br /><br />
            {/* <span onClick={handleHide} style={{fontWeight:"bold",cursor:"pointer"}}>
            Show less</span> */}
        </div>
    
</div>
 ))}

</div>




    </div>
  )
}

export default VideoDescriptionDra
