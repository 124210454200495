import { Drawer } from '@material-ui/core'
import React, { useState } from 'react'
import VideoDescriptionDrawer from './VideoDescriptionDrawer';

const VideoDescription = ({data,videoStat}) => {
    
    const[open,setOpen] = useState(false);
    const [show, setShow] = useState(false)
    console.log(data,'dta')
    const handleShow=()=>{
        setShow(true)
    }
    const handleHide=()=>{
        setShow(false)
    }

    const article =[
        {
            desc:`${data?.description}`
        }
    ]

    const getRelativeTimeLabel = (updatedAt) => {
        const creationDate = new Date(updatedAt);
        const today = new Date();
    
        // Calculate the difference in time (milliseconds)
        const diffTime = today - creationDate;
        // Convert difference in time to days
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else {
            return `${diffDays} days ago`;
        }
    };
    
    // Component to display the relative time label
    const TimeLabel = ({ updatedAt }) => (
        <span>{getRelativeTimeLabel(updatedAt)}</span>
    );
  return (
    <div style={{width:'100%'}}>
        <div id='Description_about_video' className='' style={{width:"100%",margin:"15px 0",backgroundColor:"#F2F2F2",color:'black',borderRadius:'10px',padding:"10px",height: show ? "auto" :"auto"}}>
            <div id='views_time'>
                <div style={{display:"flex",marginTop:"-8px"}}>
                    <p className='views' style={{color:"black",fontSize:"14px",margin:"0",fontWeight:"500",}}>{videoStat?.view_count} views </p>
                    <span style={{padding:'0 3px'}}>&#183;</span>
                    <p className='time' style={{color:"black",fontSize:"14px",margin:"0",fontWeight:"500",}}>
                        <TimeLabel updatedAt={data?.created_at} /></p>
                </div>

                {article.map((item, index) => (
                <div key={index} style={{color:"#131313",fontSize:"13px"}}>
                       
                    {item.desc?.slice(0,300)}

                    { !show &&
                        <span onClick={handleShow} style={{fontWeight:"bold",cursor:"pointer",marginLeft:"5px"}}>
                            ...more</span>
                    }

                    { show &&
                        <div>
                            {item.desc}
                            <br /><br />
                            <span onClick={handleHide} style={{fontWeight:"bold",cursor:"pointer"}}>
                            Show less</span>
                        </div>
                    }
                </div>
                 ))}

            </div>

        </div>
        
        <div id='Description_about_video_mobileView' className='' style={{width:"100%",color:'black',padding:"10px 0",height:"35px",width:'100%',display:"none"}}>
                <div onClick={()=>setOpen(true)} style={{display:"flex",marginTop:"-8px"}}>
                    <p className='views' style={{color:"black",fontSize:"14px",margin:"0",fontWeight:"500",}}>2M views </p>
                    <span style={{padding:'0 3px'}}>&#183;</span>
                    <p className='time' style={{color:"black",fontSize:"14px",margin:"0",fontWeight:"500",}}>6 months ago</p>

                        <span style={{fontWeight:"500",cursor:"pointer",marginLeft:"9px",}}>
                            ...more</span>
                    
                </div>
            <Drawer 
                   anchor='bottom'
                   open={open} 
                   onClose={()=>setOpen(false)}>
                    <VideoDescriptionDrawer setOpen={setOpen} />

            
                   </Drawer>
            

        </div>
      
    </div>
  )
}

export default VideoDescription
