import React from 'react'
import { GoHomeFill } from "react-icons/go";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { SiYoutubeshorts } from "react-icons/si";
import { MdOutlineDashboard, MdSubscriptions } from "react-icons/md";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const VtubeAppBar = () => {
    const history = useHistory();
  return (
    <div>
        <div id='Appbar' style={{display:"none"}} >
            <div style={{height:"70px",width:"100vw",display:"flex",alignItems:"center",justifyContent:"space-evenly",position:"fixed",bottom:"0px",left:"0",backgroundColor:"white",zIndex:'99999'}}>

                <div onClick={()=>{history.push("/vtube")}} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",cursor:"pointer",}}>
                    <GoHomeFill color='black' size={24}/>
                    <div>Home</div>
                </div>

                <div onClick={()=>{history.push("/reel")}} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                    <SiYoutubeshorts color='black' size={24}/>
                    <div>Shorts</div>
                </div>

                <div onClick={()=>{history.push("/dashboard")}} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                <MdOutlineDashboard color='black'  size={24}/>
                    <div>Dashboard</div>
                </div>

                <div onClick={()=>{history.push("/allchannel")}} style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                    <PiTelevisionSimpleBold color='black'  size={24}/>
                    <div>Channel</div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default VtubeAppBar
