import React, { useEffect, useRef, useState } from 'react'
import { MdMoreVert } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import Axios from 'axios';
import Cookies from 'js-cookie';

const RecommendedVideos = ({setVideoId,setAllVideos}) => {
  const [cookieData, setCookieData] = useState({});
  const[videoData,setVideoData] = useState()
  const [durations, setDurations] = useState({});
    const videoRef = useRef(null);

    const getRelativeTimeLabel = (updatedAt) => {
      const creationDate = new Date(updatedAt);
      const today = new Date();
  
      // Calculate the difference in time (milliseconds)
      const diffTime = today - creationDate;
      // Convert difference in time to days
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
      if (diffDays === 0) {
          return 'Today';
      } else if (diffDays === 1) {
          return 'Yesterday';
      } else {
          return `${diffDays} days ago`;
      }
  };
  
  // Component to display the relative time label
  const TimeLabel = ({ updatedAt }) => (
      <span>{getRelativeTimeLabel(updatedAt)}</span>
  );
  
    const formatDuration = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
  
    const handleLoadedMetadata = (index, event) => {
      const videoDuration = event.target.duration;
      setDurations(prevDurations => ({
        ...prevDurations,
        [index]: videoDuration,
      }));
    };
  

  // console.log(videoData,'video')

 const getVideoData = async() => {
      let data = { "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_all_videos`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
             setVideoData(response?.data?.response?.videos)
             setAllVideos(response?.data?.response?.videos)
           }
  }
  
  useEffect(()=>{
    getVideoData()
    setAllVideos(videoData)
  },[cookieData])
 
useEffect(() => {

 let data = Cookies.getJSON('name');
 setCookieData(data?.user_data) 

}, []);

const handleCountViews = async(id) => {
     setVideoId(id)
  let data = { "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
      "video_id":id
      
      }

      console.log(id,'videoid')
  const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_view`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response.data.code === true){
         
         
         return true
       }
}
  return (
    <div style={{marginTop:'70px',maxHeight:'90vh',overflowY:'scroll'}}>
      {videoData && videoData.map((item,index)=>(

      <div key={index} onClick={()=>handleCountViews(item?.video_id)} id='RecommendedVideos' style={{padding:"10px",maxWidth:"402px"}}>

        <div id='Recommended_Cards' style={{display:"flex",cursor:"pointer"}}>
          <div className='ThumbnailImg' style={{position:"relative"}}>

            <div id="Video_image" style={{width:"168px",height:"94px"}}>
                <video  ref={videoRef => videoRef && videoRef.addEventListener('loadedmetadata', event => handleLoadedMetadata(index, event))} src={item?.file} loop muted alt="" style={{width:"100%",height:"100%",objectFit:"cover",borderRadius:"5px"}} />
            </div>

            <div style={{position:"absolute",bottom:"5px",right:'5px',backgroundColor:"#00000090",      color:"white",padding:"1px 4px",fontSize:"12px",borderRadius:"5px"}}>
            {formatDuration(durations[index] || 0)}
            </div>
          </div>

          <div className="description"  style={{margin:"0 10px",width:'45%'}}>

            <div className='desc' style={{width:'100%'}}>
                <p id='recommended_desc' style={{fontSize:"14px",fontWeight:"500",color:"black",marginBottom:"5px",lineHeight:"20px",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.title} </p>
            </div>
                    
            <div className="channelDesc" style={{display:"flex",justifyContent:'space-between'}}>
              <div>


                        <div className="channelName" style={{display:"flex",alignItems:'center'}}>
                            <p  style={{fontSize:"12px",margin:"0"}}>{item?.channel_name}</p>
                            <div className='blueTick' style={{marginLeft:"5px"}}><FcApproval /></div>
                            
                        </div>

                        <div style={{display:"flex",marginTop:"-8px"}}>
                            <p className='views' style={{color:"#606060",fontSize:"12px",margin:"0"}}>{item?.view_count ? item?.view_count:'0'} views </p>
                            <span style={{padding:'0 3px'}}>&#183;</span>
                            <p className='time' style={{color:"#606060",fontSize:"12px",margin:"0"}}><TimeLabel updatedAt={item?.created_at}/></p>
                        </div>
              </div>

                        <div id="Recommended_more" style={{padding:"5px",display:"none"}}>
                          <MdMoreVert size={20} /></div>

                    </div>
                    
                </div>

                <div id="Disable_more" style={{padding:"5px 0"}}>
                          <MdMoreVert size={20} /></div>


        </div>

      </div>
      ))}
      
    </div>
  )
}

export default RecommendedVideos
