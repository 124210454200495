import React, { useState } from 'react'
import VtubeNavbar from './VtubeNavbar'
import VtubeSidebar from './VtubeSidebar'
import VtubeContents from './VtubeContents'
import VtubeAppBar from './VtubeAppBar'
import VtubeUploadVideo from './VtubeUploadVideo'

const Vtube = () => {
  const [open,setOpen] = useState(false)
  const [load,setLoad] = useState(false);
  return (
    <div>

      <VtubeNavbar open={open} setOpen={setOpen} setLoad={setLoad}/>
      <div style={{display:"flex"}}>
      <VtubeSidebar open={open} setOpen={setOpen}/>
      <VtubeContents open={open} setOpen={setOpen} load={load} setLoad={setLoad}/>
      </div>
      <VtubeAppBar />
    </div>
  )
}

export default Vtube
