import { Avatar, Box } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { IoMdMore } from 'react-icons/io'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


const VtubeCard = ({data,setLoad}) => {
    const history = useHistory();
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);

    const getRelativeTimeLabel = (updatedAt) => {
      const creationDate = new Date(updatedAt);
      const today = new Date();
  
      // Calculate the difference in time (milliseconds)
      const diffTime = today - creationDate;
      // Convert difference in time to days
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
      if (diffDays === 0) {
          return 'Today';
      } else if (diffDays === 1) {
          return 'Yesterday';
      } else {
          return `${diffDays} days ago`;
      }
  };
  
  // Component to display the relative time label
  const TimeLabel = ({ updatedAt }) => (
      <span>{getRelativeTimeLabel(updatedAt)}</span>
  );
  
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration);
      }
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      };

    console.log(duration,'duration')

    
  return (
    <div onClick={()=>history.push(`/vtube/${data?.video_id}`)} className='cards' style={{display:'flex',flexDirection:'column',width:"100%",cursor:"pointer"}}>

                {/* // this is thumbnail images part */}
                <Box>
                <div className='UpperImage' style={{width:"100%",minHeight:"100%",borderRadius:"15px",position:"relative"}}>
                    <video 
                    ref={videoRef}
           onLoadedMetadata={handleLoadedMetadata} src={data?.file} alt="Not available"    muted className='vtubeVideo' style={{width:'100%',objectFit:"cover",borderRadius:"15px"}} />
                    <div style={{position:"absolute",bottom:"10px",right:'10px',backgroundColor:"#00000090",color:"white",padding:"1px 4px",fontSize:"12px",borderRadius:"5px"}}>
                    {formatDuration(duration)}

                    </div>
                </div>
                </Box>

                {/* // this is the video description part */}
                <div className='LowerDescription' style={{display:'flex',width:'100%'}}>
                    <div className='ChannelLogo' style={{marginTop:'12px'}}>
                        
                        <Avatar  src="https://yt3.ggpht.com/f5tZFBCVg3AzgtXQY3Y-1YxgH7TZPgKfAn6E7TgeAkiuxZqkRQeZsCKlr7iGM5BI48VE_Vz3Ww=s68-c-k-c0x00ffffff-no-rj" />
                        
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                    <div className="videodescription" style={{padding:" 0 10px"}}>
                        <div className='description' style={{fontSize:"16px",fontWeight:"500",color:"black",width:"100%",marginBottom:"5px",lineHeight:"18px",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                        {data?.title}
                        </div>

                        <div className='channelName'>
                            <p style={{color:"#606060",fontSize:"14px",margin:"0"}}>{data?.channel_name}</p>
                        </div>

                        <div style={{display:"flex",marginTop:"-8px"}}>
                            <p className='views' style={{color:"#606060",fontSize:"14px",margin:"0"}}>
                                {data?.view_count ? data?.view_count:'0'} views </p>
                            <span style={{padding:'0 5px'}}>&#183;</span>
                            <p className='time' style={{color:"#606060",fontSize:"14px",margin:"0"}}><TimeLabel updatedAt={data?.created_at} /></p>
                        </div>
                    </div>
                    <div className="more" style={{marginTop:"12px",paddingRight:"10px",width:'40px'}}>
                    <IoMdMore color='black' size={24} />

                    </div>

                    </div>


                </div>
            </div>
  )
}

export default VtubeCard
