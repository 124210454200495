import React, { useState } from 'react'
import VtubeNavbar from '../VtubeNavbar'
import VtubeSidebar from '../VtubeSidebar'
import VtubeAppBar from '../VtubeAppBar'
import { Drawer } from '@material-ui/core'
import SearchContent from './SearchContent'
const SearchVideo = () => {
    const [open,setOpen] = useState(false)
    const [query,setQuery] = useState('')
    
  return (
    <div>
        <VtubeNavbar open={open} setOpen={setOpen} query={query}/>
     
      <div style={{display:"flex"}}>
        <VtubeSidebar open={open} setOpen={setOpen}/>
           <SearchContent setQuery={setQuery}/>
        <VtubeAppBar />
      </div>
      
    </div>
  )
}

export default SearchVideo
