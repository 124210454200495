import { CircularProgress, Dialog, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5';
import Cookies from 'js-cookie';

const VtubeUploadVideo = ({isDialogOpen, setIsDialogOpen,setLoad}) => {
    const [fileType, setFileType] = useState();
    const [message, setMessage] = useState('');
    const [imgPreview, setImgPreview] = useState();
    const [imgFile, setImgFile] = useState();
    const [cookieData, setCookieData] = useState({});
    const[progress,setProgress] = useState(false);
    const [channel,setChannel] = useState({});

    const formik = useFormik({
        initialValues: {
            video: '',
            title: '',
            description: '',
            audience: '',
            visibility: ''
        },
        validate: values => {
            const errors = {};
            if (!values.title) {
                errors.title = 'Required*';
            }
            if (!values.description) {
                errors.description = 'Required*';
            }
         
            return errors;
        },
        onSubmit: async(values) => {
            const {description,title,audience,visibility} = values
            const data = {description,title,audience,visibility}
               setProgress(true)
                try {
                  if(Object.keys(cookieData).length > 0 ){
                    let formdata = new FormData()
                    formdata.append('file',imgFile)
                    formdata.append('file_type',fileType)
                    formdata.append('api_key',cookieData?.api_key)
                    formdata.append('user_id',cookieData?.id)
                    formdata.append('title',data?.title)
                    formdata.append('channel_id',channel?.id)
                    formdata.append('description',data?.description)
                    formdata.append('made_for_kids',data?.audience)
                    formdata.append('status',data?.visibility)
                    console.log(formdata,'formdata')
                   let response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_video`,
                   formdata,
                   {
                    headers:{
                      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
                    }
                   }
                  
                  );
                  if(response.data.code === true){
                    setIsDialogOpen(false)
                    setLoad(true)
                    setProgress(false)
                   
                  }
              
                
                  }
              
                } catch (error) {
                    setProgress(false)
                  console.log(error,'error')
                }
              
              
              
        }
    });

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const handleImagePreview = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let file_type = '';
        if (image_as_files.type.indexOf('image') === 0) {
            file_type = 'image';
        } else {
            file_type = 'video';
        }
        setImgPreview(image_as_base64)
        setImgFile(image_as_files)
        setFileType(image_as_base64)
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
    
      
      }, []);

      useEffect(() => {

        let data2 = Cookies.getJSON('channel');
        setChannel(data2) 
       
      }, []);

    return (
        <div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'20px'}}>
                <h4>Upload Videos</h4>
                <IoCloseSharp onClick={closeDialog} style={{fontSize:'25px',cursor:'pointer'}}/>
            </div>
            {
                fileType ? (
                    <>
                        <form onSubmit={formik.handleSubmit}>
                            <div style={{width:'90%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',display:'flex',alignItems:'center',margin:'auto',justifyContent:'center'}}>
                                <div style={{height:'278px',width:'100%',borderRadius:'15px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <video alt="Preview"  autoPlay loop muted
                                    id='video' style={{height:'280px', width:'100%'}}
                                
                                      
                                     src={fileType} />
                                </div>
                            </div>

                            <div style={{marginTop:'20px'}}>
                                <div style={{color:"black",fontWeight:'500',fontSize:"20px"}}>Details</div>

                                <div>Title</div>
                                <input
                                    id='title'
                                    name='title'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title}
                                    required
                                    type='text'
                                    placeholder='Add a title that describes your video'
                                    style={{padding:'0px 10px',border:'2px solid red',height:'40px',borderRadius:'5px'}}
                                />
                                {formik.touched.title && formik.errors.title ? <div style={{marginBottom:"10px",color:"red",fontSize:"14px"}}>{formik.errors.title}</div> : null}

                                <div>Description</div>
                                <input
                                    id='description'
                                    name='description'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    required
                                    type='text'
                                    placeholder='Tell viewers about your video'
                                    style={{padding:'0px 10px',border:'2px solid black',height:'40px',borderRadius:'5px'}}
                                />
                                {formik.touched.description && formik.errors.description ? <div style={{marginBottom:"10px",color:"red",fontSize:"14px"}}>{formik.errors.description}</div> : null}

                                <div style={{color:"black",fontWeight:'500',fontSize:'16px'}}>Audience</div>
                                <div style={{color:"black",fontWeight:'500',fontSize:"12px"}}>Is this video 'Made for Kids'? (required)</div>

                                <div style={{fontSize:"12px"}}>Regardless of your location, you're legally required to comply with the Children's Online Privacy Protection Act (COPPA) and/or other laws. You're required to tell us whether your videos are 'Made for Kids'. <a href="https://support.google.com/youtube/answer/9528076?hl=en-IN" style={{color:'blue'}}>What is 'Made for Kids' content?</a></div>

                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="audience"
                                        value={formik.values.audience}
                                        onChange={formik.handleChange}
                                    >
                                        <FormControlLabel  style={{margin:"0",color:"black"}} value="1" label="Yes, it's 'Made for Kids'"  
                                        control={<Radio checked={formik.values.audience === "1"}/>} />
                                        <FormControlLabel  style={{margin:"0",color:"black"}} value="0" 
                                        control={<Radio checked={formik.values.audience === "0"}/>} label="No, it's not 'Made for Kids'" />
                                    </RadioGroup>
                                </FormControl>
                                {formik.touched.audience && formik.errors.audience ? <div style={{marginBottom:"10px",color:"red",fontSize:"14px"}}>{formik.errors.audience}</div> : null}

                                <div style={{color:"black",fontWeight:'500',fontSize:"20px"}}>Visibility</div>

                                <div style={{fontSize:"12px"}}>Choose when to publish and who can see your video</div>

                                <div style={{border:"1px solid black",padding:'20px',margin:"20px 0",borderRadius:"5px"}}>
                                    <div style={{color:"black",fontWeight:'500',fontSize:'16px'}}>Save or publish</div>
                                    <div style={{fontSize:"12px"}}>Make your video public, unlisted or private</div>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="visibility"
                                            value={formik.values.visibility}
                                            onChange={formik.handleChange}
                                        >
                                            <FormControlLabel  style={{margin:"0",color:"black"}} value="Public"
                                             control={<Radio checked={formik.values.visibility === 'public'}/>} label="Public" />
                                            <div style={{fontSize:"12px",marginLeft:"40px"}}>Everyone can watch your video</div>
                                            <FormControlLabel style={{margin:"0",color:"black"}} value="Private" 
                                            control={<Radio checked={formik.values.visibility === 'private'}/>} label="Private" />
                                            <div style={{fontSize:"12px",marginLeft:"40px"}}>Only you and people you choose can watch this video</div>
                                        </RadioGroup>
                                    </FormControl>
                                    {formik.touched.visibility && formik.errors.visibility ? <div style={{marginBottom:"10px",color:"red",fontSize:"14px"}}>{formik.errors.visibility}</div> : null}
                                </div>

                                <div style={{color:"black",fontWeight:'500',fontSize:'16px'}}>Before you publish, check the following:</div>

                                <div style={{fontSize:"13px",color:'black',margin:"10px 0 5px 0"}}>Do children appear in this video?</div>
                                <div style={{fontSize:"12px"}}>Make sure that you follow our policies to protect minors from harm, exploitation, bullying and violations of employment law.
                                    <a href="https://support.google.com/youtube/answer/2801999?hl=en-IN">Learn More</a>
                                </div>    

                                <div style={{fontSize:"13px",color:'black',margin:"10px 0 5px 0"}}>Looking for overall content guidance?</div>
                                <div style={{fontSize:"12px"}}>Our Community Guidelines can help you to avoid trouble and ensure that YouTube remains a safe and vibrant community.
                                    <a href="https://www.youtube.com/howyoutubeworks/policies/community-guidelines/#community-guidelines">Learn More</a>
                                </div>          
                            </div>

                            <div style={{margin:"20px",display:'flex',justifyContent:"end"}}>
                                <button onClick={closeDialog} style={{borderRadius:"30px",backgroundColor:"#F2F2F2",color:"black",padding:"8px 15px",border:"none",marginLeft:"10px"}}>Cancel</button>

                                <button  type="submit" style={{borderRadius:"30px",backgroundColor:"black",color:"white",padding:"8px 15px",border:"none",marginLeft:"10px",display:'flex',alignItems:'center',justifyContent:'center',gap:'10px'}}>{progress && <CircularProgress style={{color:'white'}}/>}  Upload</button>
                            </div>
                        </form>
                    </>
                ) : (
            <div style={{width:'100%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',marginTop:'30px',display:'flex',alignItems:'center'}}>
                            <label className="fileContainer" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%'}}>
                               <img src='https://png.pngtree.com/png-vector/20191129/ourlarge/pngtree-image-upload-icon-photo-upload-icon-png-image_2047546.jpg' style={{height:'80px',width:'100px'}}/>
                                <div style={{fontSize:'24px',fontWeight:'500',color:'black'}}>Add Videos</div>
                                  <div>or drag and drop</div>
                                 <input type="file" id="image_text" accept=".jpg,.jpeg,.png,.mp4" 
                                 onChange={handleImagePreview}/>
                                   </label>
            </div>
            )
            }

                        

            {
            fileType ? (
            <div style={{display:'flex',gap:'10px'}}>
            {/* <button onClick={handleDiscardStory} style={{width:'100%',height:'40px',border:'none',borderRadius:'10px',marginTop:'20px'}}>Discard</button>
                         <button onClick={handleCreateStory} style={{width:'100%',height:'40px',background:'blue',border:'none',borderRadius:'10px',marginTop:'20px'}}>Create V Tube</button> */}

            </div>
            ):("")}
      
    </div>
  )
}

export default VtubeUploadVideo
