import React, { useEffect, useState } from 'react';
import { IoMdMore } from "react-icons/io";
import Cookies from 'js-cookie';
import Axios from 'axios';
import { Avatar, Dialog } from '@material-ui/core';
import EditChannel from './EditChannel';
import { FiDelete } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';


const MyChannelContent = ({channelId,load,setLoad}) => {
    const [fileType, setFileType] = useState();
    const [cookieData, setCookieData] = useState({});
    const[channelData,setChannelData] = useState()
    const[open,setOpen] = useState(false);
    const [content,setContent] = useState();

    const getChannelData = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "channel_id":channelId?.id
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_channel`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setChannelData(response?.data?.response)
               Cookies.set('channel', response?.data?.response)
               setLoad(!load)
             }
    }

    const getDataByChannel = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "channel_id":channelId?.id
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_videos_by_channel_id`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setContent(response?.data?.videos)
              
               setLoad(!load)
             }
    }
  
    console.log(content,'content')
    useEffect(()=>{
      getChannelData()
      getDataByChannel();
    },[cookieData,channelId])

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);

      const stringAvatar = (name) => {
        const nameParts = name.split(' ');
        return {
          children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`,
        };
      }

      const formatDate = (dateString) => {
        const date = new Date(dateString); // Convert string to Date object
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, pad with zero
        const day = date.getDate().toString().padStart(2, '0'); // Pad day with zero if needed
        
        return `${year}/${month}/${day}`;
      };
    return (
        <div style={{ width: "100%" }}>
            <div style={{ marginTop: "70px", padding: '40px 20px' }}>
                <div id='channelDescription' style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Avatar style={{height:'100px',width:'100px',background:'green'}}  {...stringAvatar(`${channelData?.name}`)}/>
                    <div>
                        <div className='fontname' style={{ fontSize: "29px", fontWeight: "500", color: 'black' }}>{channelData?.name}</div>
                        <div style={{ fontSize: "14px", fontWeight: "", }}>EMAIL</div>
                        <button onClick={()=>setOpen(true)} style={{padding:'5px 10px',borderRadius:'20px',border:'none',marginTop:'5px'}}>Customise channel</button>
                        <Dialog open={open} onClose={()=>setOpen(false)}>
                            <EditChannel setOpen={setOpen} channelId={channelData?.id} data={channelData}/>
                        </Dialog>
                    </div>
                </div>

                <div>
                    <div style={{ fontSize: "24px", fontWeight: "500", color: 'black', margin: "20px 0" }}>Channel content</div>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <table style={{ width: '100%', minWidth: "870px" }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '10px', width: "200px", margin: "auto" }}>Videos</th>
                                    <th style={{ padding: '10px', textAlign: "start", margin: "auto" }}>Visibility</th>
                                    <th style={{ padding: '10px', textAlign: "start" }}>Restrictions</th>
                                    <th style={{ padding: '10px' }}>Date</th>
                                    <th style={{ padding: '10px' }}>Views</th>
                                    <th style={{ padding: '10px' }}>Comments</th>
                                    <th style={{ padding: '10px' }}>Likes</th>
                                    <th style={{ padding: '10px' }}>More</th>
                                </tr>
                            </thead>
                            <tbody>

                                {content && content.map((item,index)=>(
                                <tr>
                                    <td style={{ padding: '10px' }}>
                                        <div style={{ height: '78px', width: '100%', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {/* <img src={imgPreview} style={{height:'278px',width:'160px',borderRadius:'15px'}}/>
                                            */}
                                            <video controls alt="Reuters" typeof='video/mp4'   muted className="imgDiv2" style={{ height: '78px', objectFit: 'cover', borderRadius: '15px', width: '140px' }} src={item?.file} />
                                        </div>
                                    </td>
                                    <td style={{ padding: '10px' }}>{item?.status === null ? 'Public': item?.status}</td>
                                    <td style={{ padding: '10px' }}>{item?.made_for_kids === 0 ? 'Yes' : 'None'}</td>
                                    <td style={{ padding: '10px' }}>{formatDate(item?.created_at)}</td>
                                    <td style={{ padding: '10px' }}>{item?.view_count}</td>
                                    <td style={{ padding: '10px' }}>{item?.comment_count}</td>
                                    <td style={{ padding: '10px' }}>{item?.like_count}</td>
                                    <td style={{ padding: '10px' }}>
                                        <button style={{ borderRadius: "50px", backgroundColor: "#F2F2F2", color: "black", padding: "7px 10px", border: "none", display: "flex", alignItems: "center" }}><MdDelete color='black' size={20} />
                                        </button>
                                    </td>
                                </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyChannelContent;
