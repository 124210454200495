import { Button, TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';

const AddStatus = ({setOpen,setLoad,load}) => {
    const [cookieData, setCookieData] = useState({});
    const[place,setPlace] = useState('')
    const handleAddCity = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "status_name":place,
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/create_relationship_status`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setPlace('')
                setOpen(false)
                setLoad(!load)
             }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>Relationship status</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField variant='outlined' value={place} onChange={(e)=>setPlace(e.target.value)} fullWidth placeholder='Relationship status'/>

        

        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button onClick={handleAddCity} variant='contained'>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default AddStatus
