import { Button, TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';

const UpdateCity = ({setOpen,data,setLoadCity}) => {
    const [cookieData, setCookieData] = useState({});
    const[place,setPlace] = useState(data?.place)
    // console.log(data,'data of place')
    const handleAddCity = async() => {
        let data2 = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "place":place,
            "place_id":data?.id,
            "is_current":"0"}
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_place_lived`,
            data2,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response?.data?.code === true){
                setPlace('')
                setOpen(false)
                setLoadCity(true)
             }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>Places Lived</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField variant='outlined' value={place} onChange={(e)=>setPlace(e.target.value)} fullWidth placeholder='City State Country'/>

        

        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button onClick={handleAddCity} variant='contained'>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default UpdateCity
