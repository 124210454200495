import { Avatar, Dialog, Tooltip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { FcApproval } from "react-icons/fc";
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import { PiShareFatLight } from "react-icons/pi";
import { MdContentCopy, MdMoreHoriz } from "react-icons/md";
import VideoDescription from './VideoDescription';
import VideoComments from './VideoComments';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AiFillLike,AiFillDislike } from "react-icons/ai";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { TfiClose } from 'react-icons/tfi';




const VideoDetails = ({videoId,allVideos}) => {
    const params = useParams();
    const [cookieData, setCookieData] = useState({});
    const[videoData,setVideoData] = useState()
    const[videoStat,setVideoStat] = useState()
    const[load,setLoad] = useState(false);
    const[open,setOpen] = useState(false)
    const [channel,setChannel] = useState({});
    const[channelDetail,setChaennelDetail] = useState()
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isFunctionCalled, setIsFunctionCalled] = useState(false);

   console.log(allVideos,'allvideos')
    const videoRef = useRef(null);

  const handleNextVideo = () => {
    // Increment video index to play the next video
    console.log(allVideos,'videoend')
    if (currentVideoIndex < allVideos?.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      console.log("End of playlist or show recommendations");
      // Logic for end of the playlist or showing recommendations
    }
  };

  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const currentTime = videoElement.currentTime;
      const duration = videoElement.duration;

      // Check if current time is within 1 second of the duration
      if (duration && (duration - currentTime) < 1 && !isFunctionCalled) {
        setIsFunctionCalled(true);
        handleNextVideo();
      }
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);

      // Cleanup function
      return () => {
        if (videoElement) {
          videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        }
      };
    }
  }, [currentVideoIndex, isFunctionCalled]);
//  console.log(params,'id')
//     console.log(videoData,'video')

useEffect(() => {
  const videoElement = videoRef.current;
  if (videoElement) {
    videoElement.load(); // Reload the video when the video index changes
    videoElement.play();
    setIsFunctionCalled(false); // Reset the function called flag for the new video
  }
}, [currentVideoIndex]);



   const getVideoData = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "video_id":videoId ? videoId :params?.id
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_video`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setVideoData(response?.data?.response)
             }
    }

    const cretaeLike = async(like) => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "video_id":videoId ? videoId :params?.id,
            "reaction":like,
            "channel_id":channel?.id
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_reaction`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setLoad(!load);
               return true
             }
    }

    const getVideoStat = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "video_id":videoId ? videoId :params?.id,
            "channel_id":channel?.id
           
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_stats`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setVideoStat(response?.data?.data)
             }
    }

    const getchannelDetails = async() => {
      let data = { "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "channel_id":channel?.id
         
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_channel_details`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
             setChaennelDetail(response?.data?.data)
           }
  }

    const handleSubscribe = async() => {
      let data = { "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "channel_id":channel?.id
         
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/toggle_subscription`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
            getchannelDetails()
             return true
           }
  }
    
    useEffect(()=>{
      getVideoData();
      getVideoStat();
      getchannelDetails();
    },[cookieData,load,videoId])
   
useEffect(() => {

   let data = Cookies.getJSON('name');
   setCookieData(data?.user_data) 
  
 }, []);
  const title = 'Check this out!';
 const link = `${process.env.REACT_APP_CODE}/#/vtube/${params?.id}`
 const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Link copied to clipboard!');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  useEffect(() => {

    let data2 = Cookies.getJSON('channel');
    setChannel(data2) 
   
  }, []);
  

  return (
    <div>
        <div className='Video_and_description' id='maindiv' style={{width:"100%",marginTop:"70px"}}>
            <div  style={{borderRadius:""}}>
                <video
                 ref={videoRef}
                 onEnded={console.log('hello rnd')}
                  id='video2' src={params || videoId ? videoData?.file : allVideos[currentVideoIndex].file} controls autoPlay loop muted 
                  style={{borderRadius:"10px",objectFit:"cover",width:"100%",height:"500px",border:'none'}}
                  
                  >
                   
                </video>

            </div>


            <div id='desc2' style={{display:'flex',flexDirection:'column'}}>

            <div id='desc'>
                <p style={{fontSize:"20px",fontWeight:"700",color:"black",padding:"5px 0",margin:"0"}}>{videoData?.title}</p>
            </div>
            <div id='Videodesc_in_mobileView' style={{display:"none"}}>
                <VideoDescription data={videoData}/>
            </div>

            <div className='channelName_and_button' style={{display:'flex',justifyContent:'space-between'}}>
                <div className='channel' style={{display:'flex',alignItems:"center"}}>
                    <div className="channelLogo" style={{cursor:"pointer"}}>
                        <Avatar  src="https://yt3.ggpht.com/f5tZFBCVg3AzgtXQY3Y-1YxgH7TZPgKfAn6E7TgeAkiuxZqkRQeZsCKlr7iGM5BI48VE_Vz3Ww=s68-c-k-c0x00ffffff-no-rj" />                    
                    </div>
                    
                    <div className="channelDesc" style={{margin:"0 10px"}}>

                        <div className="channelName" style={{display:"flex",alignItems:'center'}}>
                            <p  style={{fontSize:"16px",fontWeight:"500",color:"black",margin:"0",cursor:"pointer"}}>{videoData?.channel_name}</p>
                            <div className='blueTick' style={{marginLeft:"5px"}}><FcApproval /></div>
                            
                        </div>

                        <div className='Subscribers'>
                            <p style={{marginTop:"-5px",marginBottom:'0'}}>{channelDetail?.subscriber_count} subscribers</p>
                        </div>

                    </div>
                    <div id="subscribe">

                      {channelDetail?.is_subscribed === false ?
                        <button onClick={handleSubscribe} style={{borderRadius:"30px",backgroundColor:"black",color:"white",padding:"8px 15px",border:"none",marginLeft:"10px"}}>Subscribe</button>
                        :
                        <button onClick={handleSubscribe} style={{borderRadius:"30px",backgroundColor:"gray",color:"white",padding:"8px 15px",border:"none",marginLeft:"10px"}}>UnSubscribe</button>}
                    </div>

                </div>

                <div id='buttons' style={{display:"flex",alignItems:'center',gap:"5px",marginTop:'10px'}} >
                    <div className="like">
                        <button style={{borderRadius:"30px",backgroundColor:"#F2F2F2",color:"black",padding:"8px 15px",border:"none",display:"flex",alignItems:"center"}}>
                           {videoStat?.is_liked === true ? <AiFillLike color='black' size={25} onClick={()=>cretaeLike('like')}/> : <BiLike color='black' size={25} onClick={()=>cretaeLike('like')}/>}
                                <span>{videoStat?.like_count}</span></button>
                    </div>

                    <div className="dislike">
                        <button style={{borderRadius:"30px",backgroundColor:"#F2F2F2",color:"black",padding:"8px 15px",border:"none",display:"flex",alignItems:"center"}}>

                           { videoStat?.is_disliked === true ? <AiFillDislike color='black' size={25} onClick={()=>cretaeLike('dislike')}/> : 
                            <BiDislike color='black' size={25} onClick={()=>cretaeLike('dislike')}/>}
                             <span>{videoStat?.dislike_count}</span>
                            </button>
                    </div>
                    
                    <div className="">
                        <button onClick={()=>setOpen(true)} style={{borderRadius:"30px",backgroundColor:"#F2F2F2",color:"black",padding:"8px 15px",border:"none",display:"flex",alignItems:"center"}}><PiShareFatLight color='black' size={25} /><span style={{marginLeft:"5px"}}>Share</span></button>

                        <Dialog open={open} onClose={()=>setOpen(false)}>
                     <div className="share-options">

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p style={{fontSize:'24px',fontWeight:'300'}}>Share Link</p>
                            <TfiClose onClick={()=>setOpen(false)} style={{fontSize:'20px',cursor:'pointer'}}/>
                        </div>

                        <hr></hr>

                        <div style={{display:'flex',alignItems:'center',gap:'20px',justifyContent:'center',marginTop:'10px'}}>

            <FacebookShareButton url={link} quote={title}>
              <FacebookIcon size={52} round />
            </FacebookShareButton>

            <WhatsappShareButton url={link} title={title} separator=":: ">
              <WhatsappIcon size={52} round />
            </WhatsappShareButton>

            <TwitterShareButton url={link} title={title}>
              <TwitterIcon size={52} round />
            </TwitterShareButton>

            <LinkedinShareButton url={link} title={title} summary={title}>
              <LinkedinIcon size={52} round />
            </LinkedinShareButton>

            <EmailShareButton url={link} title={title}>
                <EmailIcon size={52} round/>
            </EmailShareButton>
                        </div>

                        <hr></hr>

                        <div style={{ display: 'flex', alignItems: 'center',border:'1px solid rgba(0,0,0,0.2)' ,marginTop:'10px',padding:'10px',borderRadius:'5px 10px'}}>
        <input
          type="text"
          value={link}
          readOnly
          style={{ marginRight: '10px', padding: '0px 5px',border:'none' }}
        />

        <Tooltip title="Copy" arrow>
        <button onClick={handleCopy} style={{border:'none',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
          <MdContentCopy style={{color:'black'}}/> {/* Using FontAwesome for the copy icon */}
        </button>
        </Tooltip>
      </div>
          </div>
        </Dialog>
                    </div>
                    
                    
                    

                </div>

            </div>
            </div>


        </div>
        <div id='videodesc_in_pc' style={{display:'flex',width:'100%'}}>
            <VideoDescription data={videoData} videoStat={videoStat}/>

        </div>
        <VideoComments params={params} videoId={videoId}/>
    </div>
  )
}

export default VideoDetails
