import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import AddCity from './popups/AddCity'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Cookies from 'js-cookie';
import Axios from 'axios';
import UpdateCity from './updatePopups/UpdateCity';

const PlacesLived = () => {
  const[open,setOpen] = useState(false);
  const [cookieData, setCookieData] = useState({});
 const[cityData,setCityData] = useState();
 const[updateDialog,setUpdateDialog] = useState(false)
 const[load,setLoad] = useState(false);
 const[deleteLoad,setDeleteLoad] = useState(false);
 const [currentItem, setCurrentItem] = useState(null);

 const handleEditClick = (item) => {
   setCurrentItem(item);
   setUpdateDialog(true);
 };

 const handleDeleteWork = async(id) => {
  let data = { "user_id":cookieData?.id,
    "api_key":cookieData?.api_key,
    "place_id":id
    }
const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_place_lived`,
    data,
    {
      headers:{
        'Content-Type': 'text/plain',
        'Accept':'application/json, text/plain, */*'
      }
     });
     if(response.data.code === true){
      setDeleteLoad(true)
      setLoad(true);
      return true
     }
 };

// console.log(cityData,'citydata')

  const getCityData = async() => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_places_lived`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           setCityData(response?.data?.places_lived)
         }
}

useEffect(()=>{
  getCityData()
},[cookieData,deleteLoad,load])

  useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data) 
   
  }, []);
  return (
    <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <div>
      <h6>Places Lived</h6>
      <div onClick={()=>setOpen(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue',cursor:'pointer'}}>
        <IoIosAddCircleOutline style={{fontSize:'30px'}}/>  Add city
        </div>
        <Dialog open={open} onClose={()=>setOpen(false)}>
           <AddCity setOpen={setOpen} setLoad={setLoad} load={load}/>
        </Dialog>
        </div>


        {cityData && cityData.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div>{item?.place}</div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog} onClose={()=>setUpdateDialog(false)}>
               <UpdateCity setOpen={setUpdateDialog} data={currentItem} setLoadCity={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteWork(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}
    </div>
  )
}

export default PlacesLived
