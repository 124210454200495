import { Avatar, Dialog, Drawer, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import VideoCommentDrawer from './VideoCommentDrawer';
import { event } from 'jquery';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { MdDelete } from 'react-icons/md';
import { IoIosArrowUp } from "react-icons/io";
import { AiFillDislike, AiFillLike } from 'react-icons/ai';


const VideoComments = ({params,videoId}) => {
    const[open,setOpen] = useState(false);
    const [commentText, setCommentText]= useState("")
    const [cookieData, setCookieData] = useState({});
    const[videoStat,setVideoStat] = useState()
    const[commentData,setCommentData] = useState();
    const [load,setLoad] = useState(false)
    const [delPopup,setDelPopup] = useState(false)
    const [reply,setReply] = useState("");
   const [channel,setChannel] = useState({});
   const [isRotated, setIsRotated] = useState(false);
    


    const [activeReplyId, setActiveReplyId] = useState(null);
    const [showReplyId, setShowReplyId] = useState(null);

    const toggleReplySection = (id) => {
      setActiveReplyId(prevId => (prevId === id ? null : id));
      if(id){
       getVideoComment()
      }
    };

    const toggleReplySection2 = (id) => {
      setShowReplyId(prevId => (prevId === id ? null : id));
      setIsRotated(!isRotated)
      if(id){
       getVideoComment()
      }
    };

   const createComment = async() => {

        let data = { 
            
            "video_id":videoId ? videoId :params?.id,
            "comment":commentText,
            "channel_id":channel?.id
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_comment`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setCommentText('');
                setLoad(!load)
               return true;
             }
    }

    const createReply = async(id) => {

      let data = { 
          
          "comment_id":id,
          "reply":reply,
          "channel_id":channel?.id
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_comment_reply`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
             setReply('')
              setLoad(!load)
            
             return true;
           }
  }


  



    const getVideoStat = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "video_id":videoId ? videoId :params?.id,
            "channel_id":channel?.id
           
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_stats`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setVideoStat(response?.data?.data)
             }
    }


    const getVideoComment = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "video_id":videoId ? videoId :params?.id,
            "channel_id":channel?.id
           
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_videos_comments`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setCommentData(response?.data?.comments)
             }
             else{
                setCommentData(null);
             }
    }

    const handleDeleteComment = async(id) => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "comment_id":id
           
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_comment`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setDelPopup(false);
               getVideoComment();
             }
             
    }

    const handleLikeComment = async(id) => {
      let data = { "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "comment_id":id
         
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/like_comment`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
             getVideoComment()
           }
           
  }


  const handleDislikeComment = async(id) => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "comment_id":id
       
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/dislike_comment`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
          getVideoComment()
         }
         
}
    

useEffect(()=>{
  getVideoStat();
  getVideoComment();
 
},[cookieData,videoId,load])
  
   
useEffect(() => {

   let data = Cookies.getJSON('name');
   setCookieData(data?.user_data) 
  
 }, []);

 useEffect(() => {

  let data2 = Cookies.getJSON('channel');
  setChannel(data2) 
 
}, []);

const getRelativeTimeLabel = (updatedAt) => {
  const creationDate = new Date(updatedAt);
  const today = new Date();

  // Calculate the difference in time (milliseconds)
  const diffTime = today - creationDate;
  // Convert difference in time to days
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
      return 'Today';
  } else if (diffDays === 1) {
      return 'Yesterday';
  } else {
      return `${diffDays} days ago`;
  }
};

// Component to display the relative time label
const TimeLabel = ({ updatedAt }) => (
  <span>{getRelativeTimeLabel(updatedAt)}</span>
);

const TimeLabel2 = ({ updatedAt }) => (
  <span>{getRelativeTimeLabel(updatedAt)}</span>
);
  return (
    <div>
      <div id='Videocomments' style={{padding:"8px"}}>
        <div style={{display:"flex"}}>
            <p style={{fontSize:"20px",fontWeight:"500",padding:'2px',color:"black"}}>{videoStat?.comment_count}</p>
            <p style={{fontSize:"20px",fontWeight:"500",padding:'2px',color:"black"}}>Comments</p>
            {/* <p onClick={()=>setOpen(true)} style={{fontSize:"20px",fontWeight:"500",padding:'2px',color:"black"}}>open</p> */}
            <Drawer 
                   anchor='bottom'
                   open={open} 
                   onClose={()=>setOpen(false)}>
                      <VideoCommentDrawer setOpen={setOpen} commentData={commentData} setCommentText2={setCommentText} videoId={videoId} 
                      params={params} setLoad={setLoad} load={load}/>
                   </Drawer>
            
        </div>

               
            <div id='add_your_comment' style={{display:'flex',gap:"12px",padding:'8px 0',alignItems:"center",width:'100%'}}>

                <Avatar alt="Image" src="" />
                    <TextField
                        id=""
                        label="Add a comment..."
                        multiline
                        size="small"
                        value={commentText}
                        type='text'
                        onChange={(e)=>setCommentText(e.target.value)}
                        style={{minWidth:"90%"}}
                        maxRows={4}
                        variant="standard"
                        />
            </div>
            <div style={{display:'flex',justifyContent:'end'}}>
                <button  onClick={createComment} style={{borderRadius:"30px",backgroundColor:"#065FD4",color:"white",padding:"6px 12px",border:"none"}}>Comment</button>
            </div>

       
{commentData && commentData.map((item,index)=>(

    <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'10px'}}>

                <div key={index} id='Others_comments' style={{padding:"8px 0",display:'flex',width:'100%'}}>
                    <div className='flex gap-3 mb-3 items-center' style={{cursor:"pointer"}}>
                        <Avatar alt="Image" src="" />
                    </div>


                    <div id='comment_details' style={{display:'flex',flexDirection:"column",gap:"4px",padding:"0 10px",width:'100%'}}>
                        <div className='flex pl-1' style={{display:"flex",gap:'8px',alignItems:'center'}}>
                            {/* { item?.author  ?  */}
                                <div id='name' style={{color:'black',fontSize:'13px',fontWeight:"500",cursor:"pointer"}}>{item?.full_name}</div>
                                <div style={{color:'#606060',fontSize:'13px',fontWeight:""}}><TimeLabel updatedAt={item?.created_at}/></div>
                        </div>

                        <div>
                            <div style={{color:'black',fontSize:'14px',fontWeight:""}}>{item?.comment}</div>
                        </div>

                        <div className='flex items-center gap-1 py-2 ' style={{display:'flex',alignItems:'center',gap:'10px',padding:'8px 0'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                        {item?.is_liked ===  "1" ? 
                        <AiFillLike color='black' size={25} onClick={()=>handleLikeComment(item?.comment_id)} 
                        style={{cursor:'pointer'}}/> 
                        : 
                        <BiLike color='black' size={25} onClick={()=>handleLikeComment(item?.comment_id)}  
                        style={{cursor:'pointer'}}/>}
                        <div>{item?.like_count}</div>
                        </div>
                        <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                {item?.is_disliked ===  "1" ? 
                        <AiFillDislike color='black' size={25} onClick={()=>handleDislikeComment(item?.comment_id)} 
                        style={{cursor:'pointer'}}/> 
                        : 
                        <BiDislike color='black' size={25} onClick={()=>handleDislikeComment(item?.comment_id)}  
                        style={{cursor:'pointer'}}/>}
                        <div>{item?.dislike_count}</div>
                        </div>

                            <div  onClick={() => toggleReplySection(item?.comment_id)} style={{fontSize:"12px",color:'black',fontWeight:'500',marginLeft:"20px",cursor:"pointer"}}>Reply</div>
                            
                        </div>

                        {activeReplyId === item?.comment_id &&
                             <div style={{width:'100%'}}>
                                 <div style={{display:'flex',gap:'10px',width:'100%'}}>
                                    <Avatar style={{height:'25px',width:'25px'}}/>
                                    <TextField variant='standard' fullWidth size='small' value={reply} required placeholder='Add reply...' 
                                    onChange={(e)=>setReply(e.target.value)}/>
                                 </div>

                                 <div style={{display:'flex',width:'100%',justifyContent:'end',gap:'10px',marginTop:'10px'}}>
                                    <button onClick={() => toggleReplySection(item?.id)} style={{border:'none',borderRadius:'20px',padding:'0px 5px'}}>Cancel</button>
                                    <button onClick={()=>createReply(item?.comment_id)}  style={{border:'none',borderRadius:'20px',padding:'0px 5px'}}>Reply</button>
                                 </div>
                             </div>
                         }



                          <div onClick={()=>toggleReplySection2(item?.comment_id)} style={{display:'flex',alignItems:'center',gap:'5px',cursor:'pointer'}}>
                            <IoIosArrowUp style={{
          color: 'blue',
          transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease', // Adds smooth transition
        }}/>
                             <div style={{color:'blue',fontSize:'14px'}}>{item?.replies?.length} replies</div>
                          </div>

                          {showReplyId === item?.comment_id &&
                          <div>

                     {item?.replies && item?.replies.map((item,index)=>(

                          <div key={index} style={{display:'flex',gap:'10px',width:'100%',marginTop:'10px'}}>
                                    <Avatar style={{height:'25px',width:'25px'}}/>

                                    <div style={{width:'100%'}}>
                                     <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                                     <div id='name' style={{color:'black',fontSize:'13px',fontWeight:"500",cursor:"pointer"}}>{item?.full_name}</div>
                                     <div style={{color:'#606060',fontSize:'13px',fontWeight:""}}><TimeLabel2 updatedAt={item?.created_at}/></div>
                                     </div>
                                         <div style={{fontSize:'12px',width:'100%'}}>{item?.reply}</div>
                                     </div>
                                 </div>
                     ))}


                          </div>
}
                    </div>
                
                </div>

                <div><MdDelete onClick={()=>setDelPopup(true)} style={{cursor:'pointer'}}/></div>
                <Dialog open={delPopup} onClose={()=>setDelPopup(false)}>
                    <div style={{width:'250px',height:'auto',padding:'10px',display:'flex',flexDirection:'column',gap:'10px'}}>
                       <div style={{fontWeight:'500'}}>Delete Comment</div>
                       <div style={{textAlign:'center'}}>Delete your comment permanently?</div>

                       <div style={{display:'flex',gap:'10px',justifyContent:'end'}}>
                          <button onClick={()=>setDelPopup(false)} style={{borderRadius:'20px',padding:'0px 10px'}}>Cancel</button>
                          <button onClick={()=>handleDeleteComment(item?.id)} style={{borderRadius:'20px',padding:'0px 10px'}}>Delete</button>
                       </div>
                    </div>
                </Dialog>
                </div>
))}
      </div>


      {commentData?.length> 0 && 

      <div id='Video_Comments_in_mobile' onClick={()=>setOpen(true)} className='' style={{width:"100%",margin:"15px 0",backgroundColor:"#F2F2F2",color:'black',borderRadius:'10px',padding:"10px",height:"85px",display:"none"}}>
            <div id='Others_comments' style={{padding:"8px 0",display:'flex'}}>
                    <div className='flex gap-3 mb-3 items-center' style={{cursor:"pointer"}}>
                        <Avatar alt="Image" src="" />
                    </div>


                    <div id='comment_details' style={{display:'flex',flexDirection:"column",gap:"4px",padding:"0 10px"}}>
                        <div className='flex pl-1' style={{display:"flex",gap:'8px'}}>
                            {/* { item?.author  ?  */}
                                <div id='name' style={{color:'black',fontSize:'13px',fontWeight:"500",cursor:"pointer"}}>
                                  {commentData && commentData[0]?.full_name}</div>
                                <div style={{color:'#606060',fontSize:'13px',fontWeight:""}}>1 Year ago</div>
                        </div>

                        <div>
                            <div style={{color:'black',fontSize:'14px',fontWeight:""}}>{commentData && commentData[0]?.comment}</div>
                        </div>

                        

                    </div>
                
                </div>
                
            

        </div>
}

        
    </div>
  )
}

export default VideoComments
