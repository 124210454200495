import { Avatar, Button, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Axios from 'axios';
import Cookies from 'js-cookie';

const EditChannel = ({setOpen,channelId,data}) => {
    const [cookieData, setCookieData] = useState({});
    const [channel,setChannel] = useState(data?.name)
    const updateChannelData = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "channel_id":channelId,
            "channel_name":channel,
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_channel`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setOpen(false)
               return true
             }
    }
    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <Avatar style={{height:'100px',width:'100px'}} />
            <IoClose onClick={()=>setOpen(false)} style={{fontSize:'25px'}}/>
          </div>
        <div style={{marginTop:'20px'}}>
            <TextField value={channel} onChange={(e)=>setChannel(e.target.value)} fullWidth variant='outlined'placeholder='Channel name'/>
        </div>
        <div style={{marginTop:'20px',display:'flex',gap:'20px'}}>
            <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
            <Button onClick={updateChannelData} variant='contained'>Save</Button>
        </div>
    </div>
  )
}

export default EditChannel
