import { Card, Grid } from '@material-ui/core'
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import Pagination from 'react-js-pagination';




const SearchContent = ({setQuery}) => {
    const [cookieData, setCookieData] = useState({});
    const[searchText,setSearchText] = useState('')
   const [searchData,setSearchData] = useState()
   const history = useHistory()
   const [duration, setDuration] = useState(0);
   const params = useParams()
   const videoRef = useRef(null);

   const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Calculate the items to display on the current page
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchData?.slice(indexOfFirstItem, indexOfLastItem);

  
  
  
    const handleSearchVideos = async() => {
      let data = { "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "search_term":params?.text,
          "page": activePage,
          "limit": itemsPerPage
          
          }
          setQuery(params?.text)
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/search_videos`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
             setSearchData(response?.data?.response?.videos)
             setQuery(params?.text)
             return true
           }
  }
  
  
  
  
  console.log(searchData,'ssearchdata')
  
  useEffect(()=>{
    handleSearchVideos()
  },[cookieData,params,activePage])
      
  
    useEffect(() => {
  
      let data = Cookies.getJSON('name');
      setCookieData(data?.user_data) 
     
    }, []);

   
    
      const handleLoadedMetadata = () => {
        if (videoRef.current) {
          setDuration(videoRef.current.duration);
        }
      };
  
      const formatDuration = (seconds) => {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = Math.floor(seconds % 60);
          return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        };

        // console.log(duration,'duration')
  return (
    <div className='searchContainer' style={{width:'100%',marginTop:'80px',marginLeft:'40px'}}>
           <div style={{width:'100%',display:'flex',flexDirection:'column',gap:'40px'}}>

         {searchData && searchData.map((item,index)=>(
         <div key={index} onClick={()=>history.push(`/vtube/${item?.video_id}`)} className='searchResult' style={{width:'100%',display:'flex',gap:'20px'}}>
         <Card style={{height:'300px',width:'35%'}} className='searchCard'>
         <div className='UpperImage' style={{width:"100%",minHeight:"100%",borderRadius:"15px",position:"relative"}}>
                    <video 
                    ref={videoRef}
                    onLoadedMetadata={handleLoadedMetadata}
            src={item?.file} alt="Not available"    muted className='vtubeVideo2' style={{width:'100%',objectFit:"cover",borderRadius:"15px"}} />
                    <div style={{position:"absolute",bottom:"10px",right:'10px',backgroundColor:"#00000090",color:"white",padding:"1px 4px",fontSize:"12px",borderRadius:"5px"}}>
                    {formatDuration(duration)}

                    </div>
                </div>
             </Card>

             <div style={{width:'40%'}}>
                <div style={{fontSize:'18px',fontWeight:'500'}}>{item?.title}</div>
                <div style={{display:'flex',gap:'10px'}}>
                    <div>{item?.channel_name}</div>
                    <div>. {item?.view_count} Views</div>
                </div>
                <div style={{fontSize:'12px'}}>{item?.description}</div>
             </div>
         </div>

         ))}


         <div style={{display:'flex',justifyContent:'center'}}>
         <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={searchData?.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
         </div>

    </div>
    </div>
  )
}

export default SearchContent
