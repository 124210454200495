import { Button, TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';

const UpdateEducation = ({setOpen,data,setLoadEdu}) => {
  const[school,setSchool] = useState(data?.school)
  const[course,setCourse] = useState(data?.degree)
  const[field,setField] = useState(data?.field_of_study)
  const[start,setStart] = useState(data?.start_date)
  const[end,setEnd] = useState(data?.end_date)
  const [cookieData, setCookieData] = useState({});


  console.log(data,'data of edu')

  const handleAddEducation = async() => {
    let data2 = { 
        "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "school": school,
        "degree": course,
        "field_of_study": field,
        "start_date": start,
        "end_date":end,
        "education_id":data?.id,
        "is_current": false}
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_education`,
        data2,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
            setSchool('')
            setOpen(false)
            setLoadEdu(true)
         }
}

useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data) 
   
  }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>University/Clg</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField variant='outlined' value={school} onChange={(e)=>setSchool(e.target.value)} fullWidth placeholder='School'/>

        <TextField variant='outlined' value={course} onChange={(e)=>setCourse(e.target.value)} fullWidth placeholder='Course'/>

        <TextField variant='outlined' value={field} onChange={(e)=>setField(e.target.value)} fullWidth placeholder='Field of study'/>


      <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
        <TextField variant='outlined' onChange={(e)=>setStart(e.target.value)} value={start}  fullWidth label='start' type='date'/>
        <div>to</div>
        <TextField variant='outlined' onChange={(e)=>setEnd(e.target.value)}  value={end} fullWidth label='End' type='date'/>
        </div>

    

        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button onClick={handleAddEducation} variant='contained'>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default UpdateEducation
