import React, { useEffect, useState } from 'react'
import { IoSearch } from 'react-icons/io5'
import Cookies from 'js-cookie';
import Axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const VtubeSearchBar = ({query}) => {
  const [cookieData, setCookieData] = useState({});
  const[searchText,setSearchText] = useState(query)
 const [searchData,setSearchData] = useState()
 const history = useHistory()

  const handleSearchVideos = async() => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "search_term":searchText,
        "page": 1,
        "limit": 5
        
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/search_videos`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           setSearchData(response?.data?.response?.videos)
           return true
         }
}




console.log(searchData,'ssearchdata')

useEffect(()=>{
  setSearchText(query)
}, [query])
useEffect(()=>{
  handleSearchVideos()

},[searchText, query])
    

  useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data) 
   
  }, []);

  const handleSearchNavigation = (title) => {
    setSearchText(`${title}`);
    if(title){
     history.push(`/searchVideos/${title}`)
      setSearchData(null)
    }
 }
 
 const handleKeyDown = (e) => {
   if (e.key === "Enter") {
      handleSearchNavigation(searchText)
   }
 };
 console.log(query,'query in search')
 console.log(searchText,'text of search')
  return (
    <div  style={{width:'100%',display:'flex',position:'relative'}}>
            <div  style={{width:'90%',height:'40px',border:'1px solid black',borderRadius:'15px 0px 0px 15px'}}>
                <input type='text'placeholder='Search' style={{padding:'0px 20px',margin:'0px',height:'40px',borderRadius:'15px 0px 0px 15px',color:"black",fontWeight:"500"}} value={searchText ? searchText : query} onChange={(e)=>setSearchText(e.target.value)} 
                onKeyDown={handleKeyDown}/>
            </div>
            <div onClick={()=>handleSearchNavigation(searchText)}  style={{width:'10%',height:'40px',borderWidth:'1px 1px 1px 0px',borderColor:'black',borderStyle:'solid',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0px 15px 15px 0px'}}>
               <IoSearch style={{fontSize:'25px'}}/>
            </div>

{searchText &&
            <div id='searchItems' style={{}}>

                <div id='searchedItem'>

                  {searchData && searchData.map((item,index)=>(
                    <div onClick={()=>handleSearchNavigation(item?.title)} key={index}  style={{padding:"10px",color:'black',display:"flex",alignItems:"center",gap:'6px'}}>
                      <IoSearch color='black' style={{fontSize:'17px'}}/>
                       <div>{item?.title}</div>
                    </div>
                  ))}
                    
                   
                </div>

            </div>
}

    </div>
  )
}

export default VtubeSearchBar
