import { Button, TextField } from '@material-ui/core'
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { IoClose } from 'react-icons/io5'

const UpdateFamily = ({setOpen,data,setLodaFamily}) => {
    const [cookieData, setCookieData] = useState({});
    const[family,setFamily] = useState(data?.name)
    const[relation,setRelation] = useState(data?.relationship)
    const handleAddFamily = async() => {
        let data2 = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "name":family,
            'relationship':relation,
            "is_current":"0",
          "id":data?.id}
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_family_relationship`,
            data2,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setFamily('')
                setOpen(false)
                setLodaFamily(true)
                
             }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>Family</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField variant='outlined' value={family} onChange={(e)=>setFamily(e.target.value)} fullWidth placeholder='Name'/>

        <TextField variant='outlined' value={relation} onChange={(e)=>setRelation(e.target.value)} fullWidth placeholder='Relationship'/>

        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button onClick={handleAddFamily} variant='contained'>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default UpdateFamily
