import React, { useState } from 'react'
import VtubeNavbar from '../VtubeNavbar'
import VtubeSidebar from '../VtubeSidebar'
import VtubeAppBar from '../VtubeAppBar'
import ChannelContent from './ChannelContent'
import { Drawer } from '@material-ui/core'
const Allchannel = () => {
    const [open,setOpen] = useState(false)
    const [channelId,setChannelId] = useState()
    
  return (
    <div>
        <VtubeNavbar open={open} setOpen={setOpen} />
          <Drawer 
                   anchor='left'
                   open={open} 
                   onClose={()=>setOpen(false)}>
                    <div style={{marginTop:"40px"}}>
                      <VtubeSidebar open={open} setOpen={setOpen} />
                      </div>
                   </Drawer>
      <div style={{display:"flex"}}>
        <VtubeSidebar open={open} setOpen={setOpen}/>
        <ChannelContent setChannelId={setChannelId}/>
        <VtubeAppBar />
      </div>
      
    </div>
  )
}

export default Allchannel
