import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import AddDeatail from './popups/AddDeatail'
import Axios from 'axios'
import Cookies from 'js-cookie';
import { FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import UpdateDeatail from './updatePopups/UpdateDetails'

const Details = () => {
  const[open,setOpen] = useState(false)
  const [cookieData, setCookieData] = useState({});
  const[cityData,setCityData] = useState();
  const[updateDialog,setUpdateDialog] = useState(false)
  const[load,setLoad] = useState(false);
  const[deleteLoad,setDeleteLoad] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
 
  const handleEditClick = (item) => {
    setCurrentItem(item);
    setUpdateDialog(true);
  };
 
  const handleDeleteWork = async(id) => {
   let data = { "user_id":cookieData?.id,
     "api_key":cookieData?.api_key,
     "place_id":id
     }
 const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_detail`,
     data,
     {
       headers:{
         'Content-Type': 'text/plain',
         'Accept':'application/json, text/plain, */*'
       }
      });
      if(response.data.code === true){
       setDeleteLoad(true)
       return true
      }
  };
 
 // console.log(cityData,'citydata')
 
   const getCityData = async() => {
     let data = { "user_id":cookieData?.id,
         "api_key":cookieData?.api_key,
         }
     const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_details_by_userid`,
         data,
         {
           headers:{
             'Content-Type': 'text/plain',
             'Accept':'application/json, text/plain, */*'
           }
          });
          if(response.data.code === true){
            setCityData(response?.data?.details)
          }
 }
 
 useEffect(()=>{
   getCityData()
 },[cookieData,deleteLoad,load])
 
   useEffect(() => {
 
     let data = Cookies.getJSON('name');
     setCookieData(data?.user_data) 
    
   }, []);
  return (
    <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
    <div>
  <h6>About you</h6>
  <div onClick={()=>setOpen(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue'}}>
    <IoIosAddCircleOutline style={{fontSize:'30px'}}/> Write some destails about yourself
    </div>
    <Dialog open={open} onClose={()=>setOpen(false)}>
      <AddDeatail setOpen={setOpen} setLoad={setLoad} load={load}/>
    </Dialog>
    </div>

    {cityData && cityData.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>

          <div>
          <div style={{fontSize:'14px'}}>Nickname: {item?.nickname}</div>
          <div style={{fontSize:'14px'}}>Favourite quote: {item?.favorite_quotes}</div>
          <div style={{fontSize:'14px'}}>Description: {item?.about_description}</div>
          </div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog} onClose={()=>setUpdateDialog(false)}>
               <UpdateDeatail setOpen={setUpdateDialog} data={currentItem} setLoadDetail={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteWork(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}


</div>
  )
}

export default Details
