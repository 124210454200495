import { Button, Checkbox, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import Cookies from 'js-cookie';
import Axios from 'axios';
import { data } from 'jquery';



const UpdateWork = ({setOpen,activeTab,data,setLoadWork}) => {
  const[current,setCurrent] = useState(false)
  const [cookieData, setCookieData] = useState({});
  const[workData,setWorkData] = useState()
  const [load,setLoad] = useState(false);

  const initialValue = {
    company:data?.company,
    city:data?.city,
    position:data?.position,
    description:data?.description,
    start_date:data?.start_date,
    end_date:data?.end_date
  
  }

  console.log(activeTab,'active')

  const{handleBlur,handleSubmit,handleChange,values} = useFormik({
      initialValues:initialValue,
      onSubmit: async(values,action) =>{
         const{company,city,position,description,start_date,end_date} = values
         const data2 = {company,position,city,description,start_date,end_date,is_current:current,user_id:cookieData?.id,
          api_key:cookieData?.api_key,work_id:data?.id}

          const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_work`,
            data2,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
         
         if(response.data.code === true){
           action.resetForm()
           setOpen(false)
           setLoadWork(true)
         }
      }
  })



 

  

  useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data) 
   
  }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>Work</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <form onSubmit={handleSubmit} style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField name='company' value={values.company} onBlur={handleBlur} onChange={handleChange} required  variant='outlined' fullWidth placeholder='Company'/>

        <TextField name='position' value={values.position} onBlur={handleBlur} onChange={handleChange} variant='outlined' fullWidth placeholder='Position'/>

        <TextField name='city' value={values.city} variant='outlined'onBlur={handleBlur} onChange={handleChange} fullWidth placeholder='City'/>

       

        <TextField variant='outlined' name='description' value={values.description} onBlur={handleBlur} onChange={handleChange} fullWidth placeholder='Description' multiline rows={3}/>
         
         <div style={{display:'flex',alignItems:'center'}}>
           <Checkbox style={{color:'blue'}}/>I currently work here
         </div>
         <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
        <TextField variant='outlined' name='start_date' value={values.start_date} onBlur={handleBlur} onChange={handleChange} fullWidth label='start' type='date'/>
        <div>to</div>
        <TextField variant='outlined' name='end_date' value={values.end_date} onBlur={handleBlur} onChange={handleChange} fullWidth label='End' type='date'/>
        </div>
        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button type='submit' variant='contained'>Save</Button>
        </div>
      </form>
    </div>
  )
}

export default UpdateWork
