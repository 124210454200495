import React, { useEffect, useState } from 'react'
import Glider from 'glider-js';
import 'glider-js/glider.min.css'; 
import { BsPlusCircleFill } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import axios from 'axios'
import { getJSON } from 'jquery';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { RxVideo } from "react-icons/rx";
import { FaPlay } from "react-icons/fa";


const Dialog = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog-content" style={{padding:'20px'}}>
        {children}
      </div>
    </div>
  );
};

function ReelSlider() {



  const [isDialogOpen, setIsDialogOpen] = useState(false);
const [imgPreview,setImgPreview] = useState()
const [imgFile,setImgFile] = useState()
const[fileType,setFileType] = useState()
const [avatar,setAvatar] = useState()
// console.log(avatar,'abvatar')
const [cookieData, setCookieData] = useState({});
const[reelData,setReelData] = useState()
const[message,setMessage] = useState('')
// console.log(storyData,'response of story')

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];
    let file_type = '';
    if (image_as_files.type.indexOf('image') === 0) {
        file_type = 'image';
    } else {
        file_type = 'video';
    }
     setImgPreview(image_as_base64)
     setImgFile(image_as_files)
     setFileType(image_as_base64)
  
   
}


const bannerImageAPI =async() => {
         try {
          
           const response = await axios.post(`${process.env.REACT_APP_URL}/api/users/avatar_banner`, 
           { 'user_id': cookieData?.id, 'view_user_id': cookieData?.id, 'api_key': cookieData?.api_key },
           {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           })

           if(response){
              setAvatar(response?.data?.recdata)
           }
         } catch (error) {
          console.log(error)
         }
        }
     




// let data = Cookies.getJSON('name');

const handleCreateStory = async() =>{

  try {
    if(Object.keys(cookieData).length > 0 ){
      let formdata = new FormData()
      formdata.append('file',imgFile)
      formdata.append('file_type',fileType)
      formdata.append('api_key',cookieData?.api_key)
      formdata.append('user_id',cookieData?.id)
      formdata.append('message',message)
      console.log(formdata,'formdata')
     let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/add_reel`,
     formdata,
     {
      headers:{
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
      }
     }
    
    );
    if(response){
      setIsDialogOpen(false)
      getStoryTimeline()
    }

  
    }

  } catch (error) {
    console.log(error,'error')
  }


}

const handleDiscardStory = () => {
  setImgPreview('')
  setFileType('')
}

const getStoryTimeline = async() => {

  console.log(cookieData ,'cookiedata')
  
  if(Object.keys(cookieData).length > 0 ){

    let data = {
      'api_key':cookieData?.api_key,
      'user_id':cookieData?.id,
      'page':'1',
      "limit":"10"
    }
  try {
    let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_timeline`,
    data,
    {
      headers:{
        'Content-Type': 'text/plain',
        'Accept':'application/json, text/plain, */*'
      }
     });
    if(response){
      setReelData(response?.data?.reels)
      
      return true
    }
  } catch (error) {
    console.log(error)
  }
}
 
}

useEffect(()=> {
  if(Object.keys(cookieData).length > 0 ){
  getStoryTimeline()
  bannerImageAPI()
  }
},[cookieData])

useEffect(() => {

  let data = Cookies.getJSON('name');
  setCookieData(data?.user_data)
  if(Object.keys(cookieData).length > 0 ){
 getStoryTimeline();
 bannerImageAPI();
  }

}, []);


const handleLocation = (id) => {
  if(id){
    window.location.hash = `/reel/?reelid=${id}`;
  }
}
    useEffect(() => {
      getStoryTimeline()
        const glider = document.getElementById('glider1');
    
        if (glider) {
          new Glider(glider, {
            slidesToShow: 4,
            slidesToScroll: 1,
            draggable: false,
            arrows:true,
            rewind: true,
            duration: 0.5,
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2.2,
                  draggable: true,
                  rewind: true,
                  duration: 0.5,
                },
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 4,
                  scrollLock: false,
                  rewind: true,
                },
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  scrollLock: false,
                  rewind: true,
                },
              },
            ],
          });
        }
      }, []);

      console.log(reelData,'rel')
      // console.log(fileType,'filetype')

     
  return (

    <>
    
    <section style={{ background: 'transparent',overflowX:"hidden",border:'none',width:'100%' }} className="company_icon2 section1 glider-section1">
        <div className=" m-marketing__asFeaturedIn2 container1 glider-column1" style={{background:'white',borderRadius:'5px'}}>
             <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:'15px'}}>
               <div style={{display:'flex',gap:'5px',alignItems:'center'}}>
                <RxVideo style={{fontSize:'30px'}}/>
                <div style={{fontSize:'20px',fontWeight:'500',color:'black'}}>V Tube</div>
               </div>

               <div onClick={openDialog} style={{fontWeight:'500',cursor:'pointer'}}>Create</div>
             </div>
            <ul  id='glider1' style={{display:'flex',gap:"10px",flexDirection:'row'}}>
               


                                  {reelData && reelData.map((item,index)=>(
                                    <div onClick={()=>handleLocation(item?.reel_id)} key={index} style={{cursor:'pointer'}}>
                                     
                                    
                                    <li className='liDiv2' style={{position:'relative',boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}}>
                                    {item?.file_type !== 'image/jpeg' ? (
                                   <a href="javascript:;" rel="noopener noreferrer">
                                       <video alt="Reuters" typeof='video/mp4' autoPlay loop muted className="imgDiv2" style={{height:'100%',objectFit:'cover',borderRadius:'15px'}} src={item?.file} /></a>
                                    ):( <a href="javascript:;" rel="noopener noreferrer">
                                    <img alt="Reuters"   className="imgDiv2" style={{height:'100%',objectFit:'cover',borderRadius:'15px'}} src={item?.file} /></a>)}
                                      <div style={{position:'absolute',bottom:'-10px',left:'10px',zIndex:100}}>
                                        {item?.view_count > 0 && 
                                         <p style={{color:'white',fontWeight:'500',display:'flex',alignItems:'center'}}><FaPlay style={{color:'white'}}/>{item?.view_count}</p>}
                                       
                                      </div>

                                      {/* <div style={{position:'absolute',height:'50px',width:'50px',border:'5px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center',zIndex:100}}>
                                          <img src='https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?t=st=1712910396~exp=1712913996~hmac=87fc987f7809e106e86cfb13ffec013049e3add7e0387d7ecf637021789a4383&w=996' style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div> */}

                                      <div style={{position:'absolute',height:'420px',width:'230px',background:'rgba(0,0,0,0.25)',borderRadius:'15px'}}>

                                      </div>
                                    </li>
                                    
                                    </div>
                                  ))}

                                    

                                   
                                  
                                  
                                 
                                </ul>
                                <Link to="/reel">
                                <div style={{margin:'15px',display:'flex',justifyContent:'center'}}>
                                  <div style={{fontWeight:'500'}}>See more</div>
                                </div>
                                </Link>
                            </div>
                        </section>

                        <Dialog isOpen={isDialogOpen} onClose={closeDialog}>
                          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'20px'}}>
                         <h4>Create V Tube</h4>
                          <IoCloseSharp onClick={closeDialog} style={{fontSize:'25px',cursor:'pointer'}}/>
                          </div>
                         {
                          fileType ? (

                            <>
                            <div style={{width:'90%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',display:'flex',alignItems:'center',margin:'20px'}}>
                                <div style={{height:'278px',width:'100%',borderRadius:'15px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                     {/* <img src={imgPreview} style={{height:'278px',width:'160px',borderRadius:'15px'}}/>
                                      */}
                                       <video alt="Reuters" typeof='video/mp4' autoPlay loop muted className="imgDiv2" style={{height:'278px',objectFit:'cover',borderRadius:'15px',width:'160px'}} src={fileType} />
                                </div>
                            </div>

                            <div style={{marginTop:'20px'}}>
                              <div>About or Tagline</div>
                                <input onChange={(e)=>setMessage(e.target.value)} type='text'placeholder='write something.....' style={{padding:'0px 10px',border:'1px solid black',height:'40px',borderRadius:'5px'}}/>
                            </div>
                            </>
                          ):(
                            <div style={{width:'100%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',marginTop:'30px',display:'flex',alignItems:'center'}}>
                            <label className="fileContainer" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%'}}>
                               <img src='https://png.pngtree.com/png-vector/20191129/ourlarge/pngtree-image-upload-icon-photo-upload-icon-png-image_2047546.jpg' style={{height:'80px',width:'100px'}}/>
                                <div style={{fontSize:'24px',fontWeight:'500',color:'black'}}>Add Videos</div>
                                  <div>or drag and drop</div>
                                 <input type="file" id="image_text" accept=".jpg,.jpeg,.png,.mp4"  onChange={handleImagePreview}/>
                                   </label>
                            </div>
                          )
                         }

                        

                         {
                          fileType ? (
                         <div style={{display:'flex',gap:'10px'}}>
                         <button onClick={handleDiscardStory} style={{width:'100%',height:'40px',border:'none',borderRadius:'10px',marginTop:'20px'}}>Discard</button>
                         <button onClick={handleCreateStory} style={{width:'100%',height:'40px',background:'blue',border:'none',borderRadius:'10px',marginTop:'20px'}}>Create V Tube</button>

                         </div>
                          ):("")}
                    </Dialog>
                        </>
  )
}

export default ReelSlider;
