import { Accordion, AccordionDetails, AccordionSummary, Avatar, Dialog, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoArrowBack, IoBarChartOutline, IoBarChartSharp, IoClose, IoSettings, IoSettingsOutline, IoSettingsSharp ,IoLogOutOutline } from 'react-icons/io5'
import { IoHome } from "react-icons/io5";
import { RxVideo } from 'react-icons/rx';
import { TfiClose, TfiWallet } from "react-icons/tfi";
import { BsPerson } from "react-icons/bs";
import { GrLogout } from "react-icons/gr";
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { MdContentCopy } from 'react-icons/md';
import Axios from 'axios';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sidebar = ({close,data}) => {
    const [isOpen, setIsOpen] = useState(false);
    const[open ,setOpen] = useState(false)
    const[accord,setAccord] = useState(false)
    const [cookieData, setCookieData] = useState({});
    const[refCode,setRefCode] = useState()
    const[modelOpen,setModelOpen] = useState(false);
    const link = `${process.env.REACT_APP_CODE}/#/register/?refcode=${refCode?.referral_code}`;

    const history = useHistory();

    const handleCopy = () => {
      navigator.clipboard.writeText(link).then(() => {
        alert('Link copied to clipboard!');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    };

  const toggleAccordion = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  const toggleAccordion2 = () => {
    setOpen(!open) // Toggle the state
  };

  const toggleAccordion3 = () => {
    setAccord(!accord) // Toggle the state
  };



  const logout = () => {
    Cookies.remove('name');
  }



  const showRefferalCodeAPI = async() => {
  
    try {
      
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/show_referral_code`,
        {
          'api_key':cookieData?.api_key,
          'user_id':cookieData?.id, 
        }
       ,{headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }}
      )
      console.log(response,'response')
 
      if(response.data.code === true){
       setRefCode(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }


  console.log(refCode,'refcodein sidebar')
  console.log(link,'link')

  useEffect(()=>{
    showRefferalCodeAPI();
  },[cookieData])


  useEffect(() => {

    let data = Cookies.getJSON('name');
    setCookieData(data?.user_data)
    
  }, []);

  const title = 'Check this out!';
  return (
    <div style={{width:'300px',height:'100vh',padding:'20px'}}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <div onClick={()=>history.push(`/timeLine/${data?.id}`)} style={{display:'flex',alignItems:'center',gap:'10px'}}>
                <Avatar/>
                <Typography>{data?.full_name}</Typography>
            </div>

            <IoClose onClick={close} style={{fontSize:'25px',color:'black'}}/>
        </div>


        <Link to="/dashboard">
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <IoHome style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>Home</Typography>
        </div>
        </Link>

        <Link to="/vtube">
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <RxVideo style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>V Tube</Typography>
        </div>
        </Link>

        <Link to="/wallet">
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <TfiWallet style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>Wallet</Typography>
        </div>
        </Link>



        <li className="" style={{listStyle:'none',marginTop:'20px'}}>
      {/* Main item that controls the accordion */}
      <a href="javascript:void(0);" onClick={toggleAccordion2} className="group_arrow" style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'space-between'}}>
        <div style={{display:'flex',gap:'10px'}}>
        <img style={{ color: 'black' }} src="https://victus.club/Projects_icon_small.png" />
        <span>Freelancing</span>
        </div>

        <i
          className={`fa fa-chevron-right accordion-arrow ${
            open ? 'rotated' : ''
          }`}
          style={{ marginRight: '10px' }}
        />
      </a>

      {/* Submenu with conditional rendering based on `isOpen` state */}
      <div className={`sidebar-submenu2 ${open ? 'open' : ''}`}>
        <ul>
          <li id="li_creategroup" style={{listStyle:'none'}}>
            <Link to="/createproject">
              <span>Create New Project</span>
            </Link>
          </li>
          <li id="li_my_groups" style={{listStyle:'none'}}>
            <Link to="/MyProject">
              <span>My Projects</span>
            </Link>
          </li>
          <li id="li_discovery_groups" style={{listStyle:'none'}}>
            <Link to="/DiscoveryProject">
              <span>Discover Projects</span>
            </Link>
          </li>
        </ul>
      </div>
    </li>
         

        <li className="" style={{listStyle:'none',marginTop:'20px'}}>
      {/* Main item that controls the accordion */}
      <a href="javascript:void(0);" onClick={toggleAccordion} className="group_arrow" style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'space-between'}}>
        <div style={{display:'flex',gap:'10px'}}>
        <i className="fa fa-users" style={{ color: 'black',fontSize:'25px' }} aria-hidden="true"></i>
        <span>Groups</span>
        </div>

        <i
          className={`fa fa-chevron-right accordion-arrow ${
            isOpen ? 'rotated' : ''
          }`}
          style={{ marginRight: '10px' }}
        />
      </a>

      {/* Submenu with conditional rendering based on `isOpen` state */}
      <div className={`sidebar-submenu2 ${isOpen ? 'open' : ''}`}>
        <ul>
          <li id="li_creategroup" style={{listStyle:'none'}}>
            <Link to="/creategroup">
              <span>Create Group</span>
            </Link>
          </li>
          <li id="li_my_groups" style={{listStyle:'none'}}>
            <Link to="/MyGroup">
              <span>My Groups</span>
            </Link>
          </li>
          <li id="li_discovery_groups" style={{listStyle:'none'}}>
            <Link to="/discoverygroups">
              <span>Discover Group</span>
            </Link>
          </li>
        </ul>
      </div>
    </li>


    <li className="" style={{listStyle:'none',marginTop:'20px'}}>
      {/* Main item that controls the accordion */}
      <a href="javascript:void(0);" onClick={toggleAccordion3} className="group_arrow" style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'space-between'}}>
        <div style={{display:'flex',gap:'10px'}}>
        <i className="fa fa-users" style={{ color: 'black',fontSize:'25px' }} aria-hidden="true"></i>
        <span>Surveys</span>
        </div>

        <i
          className={`fa fa-chevron-right accordion-arrow ${
            accord ? 'rotated' : ''
          }`}
          style={{ marginRight: '10px' }}
        />
      </a>

      {/* Submenu with conditional rendering based on `isOpen` state */}
      <div className={`sidebar-submenu2 ${accord ? 'open' : ''}`}>
        <ul>
          <li id="li_creategroup" style={{listStyle:'none'}}>
            <Link to="/voteAdd">
              <span>Create Survey</span>
            </Link>
          </li>
          <li id="li_my_groups" style={{listStyle:'none'}}>
            <Link to="/MyVotes">
              <span>My Surveys</span>
            </Link>
          </li>
          <li id="li_discovery_groups" style={{listStyle:'none'}}>
            <Link to="/voteDiscovery">
              <span>Discover Surveys</span>
            </Link>
          </li>
        </ul>
      </div>
    </li>


    <Link to="/analyticstraffic">
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <IoBarChartSharp style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>Analytics</Typography>
        </div>
        </Link>


        <Link to="/setting">
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <IoSettingsSharp style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>Setting</Typography>
        </div>
        </Link>

        <Link to={`/timeLine/${cookieData?.id}`}>
        <div style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <BsPerson style={{color:'black',fontSize:'25px'}}/>
            <Typography style={{color:'black'}}>Profile</Typography>
        </div>
        </Link>

        <Link to="/">
        <div onClick={logout} style={{display:'flex',alignItems:'center',gap:'10px',marginTop:'20px'}}>
            <i className="fa fa-sign-out" style={{fontSize:'25px'}}></i>
            <Typography style={{color:'black'}}>Logout</Typography>
        </div>
        </Link>


        <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="text"
        value={link}
        readOnly
        style={{   padding: '0px 5px',border:'none',marginTop:'10px' }}
      />
      <Tooltip title="Copy" arrow>
        <button onClick={handleCopy} style={{border:'none',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
          <MdContentCopy style={{color:'black'}}/> {/* Using FontAwesome for the copy icon */}
        </button>
        </Tooltip>
    </div>

    <div style={{marginTop:'10px'}}>
      <button onClick={()=>setModelOpen(true)} style={{padding:'2px 10px',borderRadius:'10px'}}>Invite</button>

      <Dialog open={modelOpen} onClose={()=>setModelOpen(false)}>
                     <div className="share-options">

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p style={{fontSize:'24px',fontWeight:'300'}}>Share Code</p>
                            <TfiClose onClick={()=>setModelOpen(false)} style={{fontSize:'20px',cursor:'pointer'}}/>
                        </div>

                        <hr></hr>

                        <div style={{display:'flex',alignItems:'center',gap:'20px',justifyContent:'center',marginTop:'10px'}}>

            <FacebookShareButton url={link} quote={title}>
              <FacebookIcon size={52} round />
            </FacebookShareButton>

            <WhatsappShareButton url={link} title={title} separator=":: ">
              <WhatsappIcon size={52} round />
            </WhatsappShareButton>

            <TwitterShareButton url={link} title={title}>
              <TwitterIcon size={52} round />
            </TwitterShareButton>

            <LinkedinShareButton url={link} title={title} summary={title}>
              <LinkedinIcon size={52} round />
            </LinkedinShareButton>

            <EmailShareButton url={link} title={title}>
                <EmailIcon size={52} round/>
            </EmailShareButton>
                        </div>

                        <hr></hr>

                        <div style={{ display: 'flex', alignItems: 'center',border:'1px solid rgba(0,0,0,0.2)' ,marginTop:'10px',padding:'10px',borderRadius:'5px 10px'}}>
        <input
          type="text"
          value={link}
          readOnly
          style={{ marginRight: '10px', padding: '0px 5px',border:'none' }}
        />

        <Tooltip title="Copy" arrow>
        <button onClick={handleCopy} style={{border:'none',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
          <MdContentCopy style={{color:'black'}}/> {/* Using FontAwesome for the copy icon */}
        </button>
        </Tooltip>
      </div>
          </div>
        </Dialog>
    </div>


    </div>
  )
}

export default Sidebar
