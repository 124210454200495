import { Avatar, Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { BsPlusLg } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import ChannelDialog from './ChannelDialog';
import Axios from 'axios';

import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const ChannelContent = ({setChannelId}) => {
  const history = useHistory();
    const [open,setOpen] = useState(false);
    const [cookieData, setCookieData] = useState({});
    const[channelData,setChannelData] = useState()
    const[load,setLoad] = useState(false)

    console.log(channelData,'channel')

   const getVideoData = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_channels`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
               setChannelData(response?.data?.response)
             }
    }
    
    useEffect(()=>{
      getVideoData()
    },[cookieData,load])
   
useEffect(() => {

   let data = Cookies.getJSON('name');
   setCookieData(data?.user_data) 
  
 }, []);


  
 const stringAvatar = (name) => {
  const nameParts = name.split(' ');
  return {
    children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`,
  };
}

  const getChannelId = (id) => {
    setChannelId(id)
    history.push(`/mychannel/${id}`)
  }
  return (
    <div style={{width:"100%"}}>
        <div style={{display:'flex',justifyContent:'center'}}>
        <div>
            <div>
                <div style={{fontSize:"24px",fontWeight:"500",color:'black',marginBottom:"30px",padding:'0 20px'}}>All Channels</div>
            </div>
            <div id='cardHolder' style={{display:"flex",alignItems:"center",flexWrap:'wrap',height:"auto",marginBottom:"70px"}}>
                <div className="">
                        <button onClick={()=>setOpen(true)} style={{borderRadius:"30px",backgroundColor:"#F2F2F2",color:"black",padding:"8px 15px",border:"none",display:"flex",alignItems:"center",width:"280px",justifyContent:'center',margin:"0 20px"}}><BsPlusLg color='black' size={20} /><span style={{marginLeft:"5px"}}>Create a channel</span></button>

                        <Dialog open={open} onClose={()=>setOpen(false)}>
                            <ChannelDialog setOpen={setOpen} setLoad={setLoad}/>
                        </Dialog>
                </div>


                {channelData && channelData.map((item,index)=>(
                <div onClick={()=>getChannelId(item?.id)} key={index} id='SingleChannel_hover'>
                    <div id='SingleChannel' style={{display:"flex",alignItems:'center',padding:"0 20px",borderLeft:"0.5px solid #E5E5E5",minWidth:"300px",margin:"20px 0"}}>
                            <div>
                                <Avatar {...stringAvatar(`${item?.name}`)} style={{background:'green'}}/>
                            </div>
                            <div id='description_about_channel' style={{marginLeft:"15px"}}>
                                <div style={{color:'black',fontSize:"14px",margin:"0",padding:"0"}}>{item?.name}</div>
                                <div style={{color:'#8c8b8b',fontSize:"13px",margin:"0",padding:"0"}}>Email</div>
                                <div style={{color:'#8c8b8b',fontSize:"13px",margin:"0",padding:"0"}}>Subscriber</div>

                            </div>
                            <div style={{marginLeft:"auto"}}>
                                <IoMdCheckmark color='black' size={25} />
                            </div>
                    </div>
                </div>

                ))}
                

            </div>
        </div>
    </div>
    </div>
  )
}

export default ChannelContent
