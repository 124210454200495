import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Navbar = () => {
  return (
    <div className='newNavbar'>
       <div style={{display:'flex',alignItems:'center',gap:'30px'}}>
         <div className='navLogo'>
          <img src='https://nftstars.shop/images/Logo3_new.png' style={{height:'80px'}}/>
         </div>

         {/* <div className='navList'>
           <div style={{color:'white'}}>Home</div>
           <div style={{color:'white'}}>About</div>
           <div style={{color:'white'}}>Service</div>
           <div style={{color:'white'}}>Product</div>
           <div style={{color:'white'}}>Contact</div>
         </div> */}
       </div>


       <div style={{display:'flex',alignItems:'center',gap:'20px'}}>
        <Link to="/login">
          <div className='signText' style={{color:'white'}}>Sign-in</div>
        </Link>

        <Link to="/register">
       <button className='regButton3'>REGISTER</button>
       </Link>
       </div>
    </div>
  )
}

export default Navbar
