import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import AddFamily from './popups/AddFamily'
import Axios from 'axios'
import Cookies from 'js-cookie';
import { FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import UpdateFamily from './updatePopups/UpdateFamily'
import AddStatus from './popups/AddStatus'
import UpdateStatus from './updatePopups/UpdateStatus'

const Family = () => {
  const[open,setOpen] = useState(false)
  const[close,setClose] = useState(false);
  const [cookieData, setCookieData] = useState({});
  const[familyData,setFamilyData] = useState();
  const[updateDialog,setUpdateDialog] = useState(false)
  const[updateDialog2,setUpdateDialog2] = useState(false)
  const[load,setLoad] = useState(false);
  const[deleteLoad,setDeleteLoad] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentItem2, setCurrentItem2] = useState(null);
  const[statusData,setStausData] = useState();
  console.log(statusData,'statsus')
 
  const handleEditClick = (item) => {
    setCurrentItem(item);
    setUpdateDialog(true);
  };

  const handleEditClick2 = (item) => {
    setCurrentItem2(item);
    setUpdateDialog2(true);
  };
 
  const handleDeleteFamily = async(id) => {
   let data = { "user_id":cookieData?.id,
     "api_key":cookieData?.api_key,
     "id":id
     }
 const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_family_relationship`,
     data,
     {
       headers:{
         'Content-Type': 'text/plain',
         'Accept':'application/json, text/plain, */*'
       }
      });
      if(response.data.code === true){
       setDeleteLoad(true)
       return true
      }
  };

  const handleDeleteStatus = async(id) => {
    let data = { "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
      "id":id
      }
  const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_relationship_status`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response.data.code === true){
        setDeleteLoad(!deleteLoad)
        return true
       }
   };
 
 // console.log(cityData,'citydata')
 
   const getFamilyData = async() => {
     let data = { "user_id":cookieData?.id,
         "api_key":cookieData?.api_key,
         }
     const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_family_relationships_by_user`,
         data,
         {
           headers:{
             'Content-Type': 'text/plain',
             'Accept':'application/json, text/plain, */*'
           }
          });
          if(response.data.code === true){
            setFamilyData(response?.data?.recdata)
          }
 }


 const getStatusData = async() => {
  let data = { "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
      }
  const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_relationship_status`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response.data.code === true){
         setStausData(response?.data?.response)
       }
}
 
 useEffect(()=>{
   getFamilyData();
   getStatusData();
 },[cookieData,deleteLoad,load])
 
   useEffect(() => {
 
     let data = Cookies.getJSON('name');
     setCookieData(data?.user_data) 
    
   }, []);
  return (
    <div style={{display:'flex',flexDirection:'column',gap:'20px'}}>
    <div>
  <h6>Relationship</h6>
  <div onClick={()=>setClose(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue',cursor:'pointer'}}>
    <IoIosAddCircleOutline style={{fontSize:'30px'}}/>  Add a relatioship status
    </div>

    <Dialog open={close} onClose={()=>setOpen(true)}>
      <AddStatus setOpen={setClose} setLoad={setLoad} load={load}/>
    </Dialog>

    {statusData && statusData?.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div>
          <div>{item?.status_name}</div>
          
            </div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick2(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog2} onClose={()=>setUpdateDialog2(false)}>
               <UpdateStatus setOpen={setUpdateDialog2} data={currentItem2} setLoadStatus={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteStatus(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}
    </div>


    <div>
  <h6>Family members</h6>
  <div onClick={()=>setOpen(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue'}}>
    <IoIosAddCircleOutline style={{fontSize:'30px'}}/>  Add family member
    </div>
    <Dialog open={open} onClose={()=>setOpen(true)}>
      <AddFamily setOpen={setOpen} setLoad={setLoad} load={load}/>
    </Dialog>

    {familyData && familyData?.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div>
          <div>{item?.name}</div>
          <div style={{fontSize:'14px'}}>{item?.relationship}</div>
            </div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog} onClose={()=>setUpdateDialog(false)}>
               <UpdateFamily setOpen={setUpdateDialog} data={currentItem} setLodaFamily={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteFamily(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}
    </div>
</div>
  )
}

export default Family
