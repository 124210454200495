import React, { Component } from 'react';


class Modal extends Component {
  render() {
    const { isOpen, onClose, children } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={onClose}>X</button>
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;