import React, { useEffect, useState } from 'react'
import { RiSendPlane2Fill } from 'react-icons/ri'
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import Emoji from '../../../contents/Emoji'
import Axios from 'axios'
import { IoClose } from 'react-icons/io5'
import { Avatar, TextField } from '@material-ui/core'
import { FaLocationArrow } from "react-icons/fa";
import axios from 'axios';
import Cookies from 'js-cookie';
import { IoIosArrowUp } from 'react-icons/io';
import { AiFillDislike, AiFillLike } from 'react-icons/ai';

const VideoCommentDrawer = ({setOpen,commentData,videoId,params,setLoad,load}) => {
    const [replyStates, setReplyStates] = useState({});
    const [commentText,setCommentText] = useState('')
    const [cookieData, setCookieData] = useState({});
    const [channel,setChannel] = useState({});
    const [reply,setReply] = useState("");
    const [isRotated, setIsRotated] = useState(false);


 
    const [activeReplyId, setActiveReplyId] = useState(null);
    const [showReplyId, setShowReplyId] = useState(null);

    const toggleReplySection = (id) => {
      setActiveReplyId(prevId => (prevId === id ? null : id));
      if(id){
       setLoad(!load)
      }
    };

    const toggleReplySection2 = (id) => {
      setShowReplyId(prevId => (prevId === id ? null : id));
      // setIsRotated(!isRotated)
      if(id){
       setLoad(!load)
      }
    };


    const createComment = async() => {

      let data = { 
          
          "video_id":videoId ? videoId :params?.id,
          "comment":commentText,
          "channel_id":channel?.id
          }
      const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/video_comment`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response.data.code === true){
              setCommentText('');
              setLoad(!load)
             return true;
           }
  }

  const handleLikeComment = async(id) => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "comment_id":id
       
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/like_comment`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
          setLoad(!load)
         }
         
}


const handleDislikeComment = async(id) => {
  let data = { "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
      "comment_id":id
     
      }
  const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/dislike_comment`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response.data.code === true){
        setLoad(!load)
       }
       
}


  const createReply = async(id) => {

    let data = { 
        
        "comment_id":id,
        "reply":reply,
        "channel_id":channel?.id
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_comment_reply`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           setReply('')
            setLoad(!load)
          
           return true;
         }
}

    useEffect(() => {

      let data = Cookies.getJSON('name');
      setCookieData(data?.user_data) 
     
    }, []);
   
    useEffect(() => {
   
     let data2 = Cookies.getJSON('channel');
     setChannel(data2) 
    
   }, []);

   const getRelativeTimeLabel = (updatedAt) => {
    const creationDate = new Date(updatedAt);
    const today = new Date();
  
    // Calculate the difference in time (milliseconds)
    const diffTime = today - creationDate;
    // Convert difference in time to days
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return 'Yesterday';
    } else {
        return `${diffDays} days ago`;
    }
  };
  
  // Component to display the relative time label
  const TimeLabel = ({ updatedAt }) => (
    <span>{getRelativeTimeLabel(updatedAt)}</span>
  );

  const TimeLabel2 = ({ updatedAt }) => (
    <span>{getRelativeTimeLabel(updatedAt)}</span>
  );
  return (
    <div style={{height:'550px',width:'100%',position:'relative',display:'flex',flexDirection:'column'}}>
      <div style={{display:'flex',width:'98%',justifyContent:'space-between',marginTop:'10px',height:'50px',borderBottom:'1px solid black'}}>
      <div style={{height:'50px',padding:'0px 10px',borderRadius:'10px',left:'10px'}}>
      <div style={{color:'black',fontWeight:'500',fontSize:'24px'}}>Comments</div>
                  {/* <div style={{fontSize:'18px',color:'black'}}>{reelTimeline && reelTimeline[currentStoryIndex]?.message}</div> */}
                                     
                </div>
          <IoClose onClick={()=>setOpen(false)} style={{fontSize:'25px',color:'black',cursor:'pointer'}}/>
      </div>

      

      <div style={{maxHeight:'370px',overflowY:'scroll',width:'100%',display:'flex',flexDirection:'column',gap:'10px',marginTop:'10px'}}>


{commentData && commentData.map((item,index)=>(

<div key={index} id='Others_comments' style={{padding:"8px",display:'flex'}}>
                    <div className='flex gap-3 mb-3 items-center' style={{cursor:"pointer"}}>
                        <Avatar alt="Image" src="" />
                    </div>


                    <div id='comment_details' style={{display:'flex',flexDirection:"column",gap:"4px",padding:"0 10px",width:'100%'}}>
                        <div className='flex pl-1' style={{display:"flex",gap:'8px'}}>
                           
                                <div id='name' style={{color:'black',fontSize:'13px',fontWeight:"500",cursor:"pointer"}}>{item?.full_name}</div>
                                <div style={{color:'#606060',fontSize:'13px',fontWeight:""}}><TimeLabel updatedAt={item?.created_at}/></div>
                        </div>

                        <div>
                            <div style={{color:'black',fontSize:'14px',fontWeight:""}}>{item?.comment}</div>
                        </div>

                        <div className='flex items-center gap-1 py-2 ' style={{display:'flex',alignItems:'center',gap:'10px',padding:'8px 0'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                        {item?.is_liked ===  "1" ? 
                        <AiFillLike color='black' size={25} onClick={()=>handleLikeComment(item?.comment_id)} 
                        style={{cursor:'pointer'}}/> 
                        : 
                        <BiLike color='black' size={25} onClick={()=>handleLikeComment(item?.comment_id)}  
                        style={{cursor:'pointer'}}/>}
                        <div>{item?.like_count}</div>
                        </div>
                        <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                {item?.is_disliked ===  "1" ? 
                        <AiFillDislike color='black' size={25} onClick={()=>handleDislikeComment(item?.comment_id)} 
                        style={{cursor:'pointer'}}/> 
                        : 
                        <BiDislike color='black' size={25} onClick={()=>handleDislikeComment(item?.comment_id)}  
                        style={{cursor:'pointer'}}/>}
                        <div>{item?.dislike_count}</div>
                        </div>

                            <div  onClick={() => toggleReplySection(item?.comment_id)} style={{fontSize:"12px",color:'black',fontWeight:'500',marginLeft:"20px",cursor:"pointer"}}>Reply</div>
                            
                        </div>

                        {activeReplyId === item?.comment_id &&
                             <div style={{width:'100%'}}>
                                 <div style={{display:'flex',gap:'10px',width:'100%'}}>
                                    <Avatar style={{height:'25px',width:'25px'}}/>
                                    <TextField variant='standard' fullWidth size='small' value={reply} required placeholder='Add reply...' 
                                    onChange={(e)=>setReply(e.target.value)}/>
                                 </div>

                                 <div style={{display:'flex',width:'100%',justifyContent:'end',gap:'10px',marginTop:'10px'}}>
                                    <button onClick={() => toggleReplySection(item?.id)} style={{border:'none',borderRadius:'20px',padding:'0px 5px'}}>Cancel</button>
                                    <button onClick={()=>createReply(item?.comment_id)}  style={{border:'none',borderRadius:'20px',padding:'0px 5px'}}>Reply</button>
                                 </div>
                             </div>
                         }

<div onClick={()=>toggleReplySection2(item?.comment_id)} style={{display:'flex',alignItems:'center',gap:'5px',cursor:'pointer'}}>
                            <IoIosArrowUp style={{
          color: 'blue',
          transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease', // Adds smooth transition
        }}/>
                             <div style={{color:'blue',fontSize:'14px'}}>{item?.replies?.length} replies</div>
                          </div>

                          {showReplyId === item?.comment_id &&
                          <div>

                     {item?.replies && item?.replies.map((item,index)=>(

                          <div key={index} style={{display:'flex',gap:'10px',width:'100%',marginTop:'10px'}}>
                                    <Avatar style={{height:'25px',width:'25px'}}/>

                                    <div style={{width:'100%'}}>
                                     <div style={{display:'flex',gap:'10px'}}>
                                     <div id='name' style={{color:'black',fontSize:'13px',fontWeight:"500",cursor:"pointer"}}>{item?.full_name}</div>
                                     <div style={{color:'#606060',fontSize:'13px',fontWeight:""}}><TimeLabel2 updatedAt={item?.created_at}/></div>
                                     </div>
                                         <div style={{fontSize:'12px',width:'100%'}}>{item?.reply}</div>
                                     </div>
                                 </div>
                     ))}


                          </div>
}

                    </div>
                
                </div>
))}


</div>

         <div style={{display:'flex',gap:'5px',paddingLeft:'10px',width:'100%',position:'absolute',bottom:'70px'}}>
                <div style={{height:'50px',width:'50px',left:'8px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                
                <Avatar alt="Image" src="" />
                                      </div>
                                      <div style={{height:'50px',background:'rgba(0,0,0,0.1)',borderRadius:'20px',width:'85%',padding:'10px',position:'relative'}}>
                                            <div>
                                              <input value={commentText} onChange={(e)=>setCommentText(e.target.value)} placeholder='Write a comment...' style={{padding:'0px 10px',height:'40px'}}/>
                                            </div>

                                            <div style={{position:'absolute',right:'20px', top:'10px'}}>
                                            <FaLocationArrow  style={{fontSize:'25px'}} onClick={createComment}/>
                                            </div>
                                         
                                      </div>
                </div>
    </div>
  )
}

export default VideoCommentDrawer
