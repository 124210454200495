import React, { useState } from 'react'
import { RiSendPlane2Fill } from 'react-icons/ri'
import Emoji from './Emoji'
import Axios from 'axios'
import { IoClose } from 'react-icons/io5'
import { Avatar } from '@material-ui/core'

const CommentDrawer = ({commentText,setCommentText,setOpen,comments,commentLike,commentReplyText,setCommentReplyText,createCommentReply,handleReelCommentLike,createComment}) => {
    const [replyStates, setReplyStates] = useState({});
    

    const toggleReply = (index) => {
      setReplyStates(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };

    
  return (
    <div style={{height:'550px',width:'100%',position:'relative',display:'flex',flexDirection:'column'}}>
      <div style={{display:'flex',width:'98%',justifyContent:'space-between',marginTop:'10px',height:'50px',borderBottom:'1px solid black'}}>
      <div style={{height:'50px',padding:'0px 10px',borderRadius:'10px',left:'10px'}}>
      <div style={{color:'black',fontWeight:'500',fontSize:'24px'}}>Comments</div>
                  {/* <div style={{fontSize:'18px',color:'black'}}>{reelTimeline && reelTimeline[currentStoryIndex]?.message}</div> */}
                                     
                </div>
          <IoClose onClick={()=>setOpen(false)} style={{fontSize:'25px',color:'black',cursor:'pointer'}}/>
      </div>

      

      <div style={{maxHeight:'370px',overflowY:'scroll',width:'100%',display:'flex',flexDirection:'column',gap:'10px',marginTop:'10px'}}>
{comments && comments.map((item,index)=>(


               <div key={index}>
                <div style={{display:'flex',gap:'5px',paddingLeft:'10px'}}>
                <Avatar src=''/>
                                      <div style={{height:'auto',background:'rgba(0,0,0,0.1)',borderRadius:'15px',width:'auto',padding:'5px 10px'}}>
                                           <div style={{fontWeight:'500',color:'black'}}>{item?.full_name}</div>
                                           <div>{item?.comment}</div>
                                      </div>
                </div>

                <div style={{display:'flex',gap:'10px',marginLeft:'70px',flexDirection:'column'}}>

                  <div style={{display:'flex',gap:'10px'}}>
                   <div className={item?.comment_reel_liked  ? 'like5':'dislike5'} onClick={()=>handleReelCommentLike(item?.comment_id)} style={{fontWeight:'500',cursor:'pointer',fontSize:'12px'}}>Like {item?.like_count}</div>
                   <div onClick={() => toggleReply(index)} style={{fontWeight:'500',color:'black',cursor:'pointer',fontSize:'12px'}}>Reply</div>
                   {item?.replies?.length > 0 &&
                   <div onClick={() => toggleReply(index)} style={{fontWeight:'500',color:'black',cursor:'pointer',fontSize:'14px'}}>
                    View all {item?.replies?.length} Replies</div>
                   }
                 </div>


                 {replyStates[index] && 

                 <div>

                  {item && item?.replies.map((reply,index)=>(
                 <div key={index} style={{display:'flex',gap:'5px',paddingLeft:'10px',marginTop:'10px'}}>
                     <Avatar src=''/>
                                      <div style={{height:'auto',background:'rgba(0,0,0,0.1)',borderRadius:'15px',width:'auto',padding:'5px 10px'}}>
                                           <div style={{fontWeight:'500',color:'black'}}>{reply?.full_name}</div>
                                           <div>{reply?.reply}</div>
                                      </div>
                </div>
                ))}
                </div>
}
                   {replyStates[index] &&  
                   <div style={{display:'flex',gap:'5px',paddingLeft:'10px',width:'100%',top:'0px'}}>
                              <Avatar src=''/>
                                      <div style={{height:'90px',background:'rgba(0,0,0,0.1)',borderRadius:'20px',width:'80%',padding:'10px'}}>
                                            <div>
                                              <input value={commentReplyText} onChange={(e)=>setCommentReplyText(e.target.value)} placeholder='Reply on comment...' style={{padding:'0px 10px',height:'40px'}}/>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                              <Emoji/>
                                              <RiSendPlane2Fill onClick={()=>createCommentReply(item?.comment_id)} style={{cursor:'pointer',fontSize:'22px'}}/>
                                            </div>
                                      </div>
                </div>}
                  
                </div>
                </div>

))}
</div>

         <div style={{display:'flex',gap:'5px',paddingLeft:'10px',width:'100%',position:'absolute',bottom:'10px'}}>
                <div style={{height:'50px',width:'50px',border:'2px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src='https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?t=st=1712910396~exp=1712913996~hmac=87fc987f7809e106e86cfb13ffec013049e3add7e0387d7ecf637021789a4383&w=996' style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div style={{height:'90px',background:'rgba(0,0,0,0.1)',borderRadius:'20px',width:'80%',padding:'10px'}}>
                                            <div>
                                              <input value={commentText} onChange={(e)=>setCommentText(e.target.value)} placeholder='Write a comment...' style={{padding:'0px 10px',height:'40px'}}/>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                              <Emoji/>
                                              <RiSendPlane2Fill onClick={createComment} style={{cursor:'pointer',fontSize:'22px'}}/>
                                            </div>
                                      </div>
                </div>
    </div>
  )
}

export default CommentDrawer
