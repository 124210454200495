import { Button, TextField } from '@material-ui/core'
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { IoClose } from 'react-icons/io5'

const AddFamily = ({setOpen,setLoad,load}) => {
    const [cookieData, setCookieData] = useState({});
    const[family,setFamily] = useState('')
    const[relation,setRelation] = useState('')
    const handleAddFamily = async() => {
        let data = { "user_id":cookieData?.id,
            "api_key":cookieData?.api_key,
            "name":family,
            'relationship':relation,
            "is_current":"0"}
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_family_relationship`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response.data.code === true){
                setFamily('')
                setOpen(false)
                setLoad(!load)
             }
    }

    useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data) 
       
      }, []);
  return (
    <div className='addPopup'>

      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <h4>Family</h4>
        <IoClose onClick={()=>setOpen(false)} style={{fontSize:'30px',cursor:'pointer'}}/>
      </div>

      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <TextField variant='outlined' onChange={(e)=>setFamily(e.target.value)} fullWidth placeholder='Name'/>

        <TextField variant='outlined' onChange={(e)=>setRelation(e.target.value)} fullWidth placeholder='Relationship'/>

        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
          <Button onClick={()=>setOpen(false)} variant='outlined'>Cancel</Button>
          <Button onClick={handleAddFamily} variant='contained'>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default AddFamily
