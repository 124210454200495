import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import AddWork from './popups/AddWork'
import AddUniversity from './popups/AddUniversity'
import Axios from 'axios'
import Cookies from 'js-cookie';
import { FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import UpdateWork from './updatePopups/WorkUpdate'
import UpdateEducation from './updatePopups/UpdateEducation'

const WorkEdu = () => {
    const[open,setOpen] = useState(false)
    const [eduOpen,setEduOpen] = useState(false)
    const [cookieData, setCookieData] = useState({});
    const[workData,setWorkData] = useState();
    const[updateDialog,setUpdateDialog] = useState(false)
    const[updateDialog2,setUpdateDialog2] = useState(false)
    const[load,setLoad] = useState(false);
    const[deleteLoad,setDeleteLoad] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentItem2, setCurrentItem2] = useState(null);
    const[eduData,setEduData] = useState();


    console.log(currentItem2,'item')
   
    const handleEditClick = (item) => {
      setCurrentItem(item);
      setUpdateDialog(true);
    };

    const handleEditClick2 = (item) => {
      setCurrentItem2(item);
      setUpdateDialog2(true);
    };
   
    const handleDeleteWork = async(id) => {
     let data = { "user_id":cookieData?.id,
       "api_key":cookieData?.api_key,
       "work_id":id
       }
   const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_work`,
       data,
       {
         headers:{
           'Content-Type': 'text/plain',
           'Accept':'application/json, text/plain, */*'
         }
        });
        if(response.data.code === true){
         setDeleteLoad(true)
         return true
        }
    };


    const handleDeleteEdu = async(id) => {
      let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        "education_id":id
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_education`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
          setDeleteLoad(true)
          return true
         }
     };
   
   console.log(eduData,'workdata')
   
     const getWorkData = async() => {
       let data = { "user_id":cookieData?.id,
           "api_key":cookieData?.api_key,
           }
       const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_work`,
           data,
           {
             headers:{
               'Content-Type': 'text/plain',
               'Accept':'application/json, text/plain, */*'
             }
            });
            if(response.data.code === true){
              setWorkData(response?.data?.work_entries)
            }
   }

   const getEduData = async() => {
    let data = { "user_id":cookieData?.id,
        "api_key":cookieData?.api_key,
        }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_education`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           setEduData(response?.data?.education)
         }
}
   
   useEffect(()=>{
     getWorkData()
     getEduData()
   },[cookieData,deleteLoad,load])
   
     useEffect(() => {
   
       let data = Cookies.getJSON('name');
       setCookieData(data?.user_data) 
      
     }, []);

  return (
    <div style={{display:'flex',flexDirection:'column',gap:'20px'}}>
        <div>
      <h6>Work</h6>
      <div onClick={()=>setOpen(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue',cursor:'pointer'}}>
        <IoIosAddCircleOutline style={{fontSize:'30px'}}/>  Add a workplace
        </div>
        <Dialog open={open} onClose={()=>setOpen(false)}>
            <AddWork setOpen={setOpen} setLoad={setLoad} load={load}/>
        </Dialog>


        {workData && workData.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div>
          <div style={{fontSize:'15px'}}>{item?.position} in {item?.company} at {item?.city}</div>
          <div style={{fontSize:'12px'}}>{item?.description}</div>
            </div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog} onClose={()=>setUpdateDialog(false)}>
               <UpdateWork setOpen={setUpdateDialog} data={currentItem} setLoadWork={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteWork(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}
        </div>

        <div>
      <h6>Education</h6>
      <div onClick={()=>setEduOpen(true)} style={{display:'flex',alignItems:'center',gap:'10px',color:'blue',cursor:'pointer'}}>
        <IoIosAddCircleOutline style={{fontSize:'30px'}}/>  Add Education
        </div>

        <Dialog open={eduOpen} onClose={()=>setEduOpen(false)}>
           <AddUniversity setOpen={setEduOpen} setLoad={setLoad} load={load}/>
        </Dialog>

        {eduData && eduData.map((item,index)=>(
        <div key={index} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div>
          <div style={{fontSize:'15px'}}>{item?.degree} in {item?.school}</div>
          <div style={{fontSize:'12px'}}>{item?.field_of_study}</div>
            </div>
          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <div>
            <FaEdit onClick={()=>handleEditClick2(item)} style={{cursor:'pointer'}}/>

            <Dialog open={updateDialog2} onClose={()=>setUpdateDialog2(false)}>
               <UpdateEducation setOpen={setUpdateDialog2} data={currentItem2} setLoadEdu={setLoad}/>
            </Dialog>
              </div>
            <MdDelete onClick={() => handleDeleteEdu(item?.id)} style={{cursor:'pointer'}}/>
          </div>
        </div>

        ))}
        </div>


       
    </div>
  )
}

export default WorkEdu
