import React, { useState } from 'react'
import VtubeNavbar from '../VtubeNavbar'
import VtubeSidebar from '../VtubeSidebar'
import VtubeAppBar from '../VtubeAppBar'
import MyChannelContent from './MyChannelContent'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

const MyChannel = () => {
    const [open,setOpen] = useState(false)
    const params = useParams();
    const[load,setLoad] = useState(false);
    console.log(params,'params');
  return (
    <div>
      <VtubeNavbar open={open} setOpen={setOpen} channelId={params} load={load}/>
      <div style={{display:"flex"}}>
      <VtubeSidebar open={open} setOpen={setOpen}/>
      <MyChannelContent channelId={params} load={load} setLoad={setLoad}/>
      <VtubeAppBar channelId={params}/>
      </div>
    </div>
  )
}

export default MyChannel
